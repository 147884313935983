<template>
  <div class="tab-main">
    <swiper
      :options="{
        slidesPerView: 'auto',
        freeMode: true,
        initialSlide: tab - 1,
      }"
      class="swiper mySwiper"
    >
      <swiper-slide :class="{ active: tab == 0 }">
        <div
          @click="
            $router
              .push({
                name: 'NoticeList',
                query: { category: '0' },
              })
              .catch(() => {})
          "
          :class="{
            active: tab == 0,
          }"
        >
          {{ $t("filter.common.total") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 1 }">
        <div
          @click="
            $router
              .push({
                name: 'NoticeList',
                query: { category: '1' },
              })
              .catch(() => {})
          "
          :class="{
            active: tab == 1,
          }"
        >
          {{ $t("menu.customer.notice") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 2 }">
        <div
          @click="
            $router
              .push({
                name: 'NoticeList',
                query: { category: '2' },
              })
              .catch(() => {})
          "
          :class="{
            active: tab == 2,
          }"
        >
          {{ $t("menu.customer.as") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 3 }">
        <div
          @click="
            $router
              .push({
                name: 'NoticeList',
                query: { category: '3' },
              })
              .catch(() => {})
          "
          :class="{
            active: tab == 3,
          }"
        >
          {{ $t("menu.customer.proposal") }}
        </div>
      </swiper-slide>
      <!-- <swiper-slide :class="{ active: tab == 4 }">
        <div
          @click="$router.push('/customer/noticelist/4').catch(() => {})"
          :class="{
            active: $route.meta.sub_menu_num === 85,
          }"
        >
          기타
        </div>
      </swiper-slide> -->
    </swiper>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "Tab",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      tab:
        this.$route.query.category ||
        (this.$store.state.searchData[this.$route.name] &&
          this.$store.state.searchData[this.$route.name].category) ||
        0,
      /*swiperOptions: {
        slidesPerView: 'auto',
        freeMode: true
      }*/
    };
  },
  computed: {},
  watch: {
    "$route.query.category": function (newVal) {
      this.tab = newVal;
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>
