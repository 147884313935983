<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page">
<!--       <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        > -->
          <b-form @submit="onSubmit">
            <div class="filter-group row">
              <div class="col-sm-auto mb-2 mb-sm-0 col-sm-6 col-md-5">
                <div class="d-flex">
                  <select
                    class="w-auto"
                    aria-label=""
                    name="searchtype"
                    v-model="searchData.searchtype"
                  >
                    <option value="G.fit_goods_nm">
                      {{ $t("filter.common.goods_name") }}
                    </option>
                    <option value="GS.fit_goods_shaft_nm">
                      {{ $t("filter.common.shaft_name") }}
                    </option>
                    <option value="P.nickname">
                      {{ $t("filter.common.nickname") }}
                    </option>
                    <option value="O.nickname">
                      {{ $t("filter.common.location") }}
                    </option>
                  </select>
                  <div class="input-group flex-nowrap">
                    <input
                      type="text"
                      class="form-control text-truncate"
                      :placeholder="$t('text.common.input_search')"
                      :aria-label="$t('text.common.input_search')"
                      aria-describedby="button-addon"
                      name="searchname"
                      v-model="searchData.searchname"
                    />
                    <button
                      class="btn btn-primary btn-search"
                      type="submit"
                      id="button-addon"
                    ></button>
                  </div>
                </div>
              </div>

              <div class="col-sm-auto ms-sm-auto col-sm-5 col-md-4">
                <div class="d-flex">
                  <select aria-label="" v-model="searchData.findex">
                    <option value="R.reg_date">
                      {{ $t("filter.common.sort_by_date") }}
                    </option>
                    <option value="R.state_cd">
                      {{ $t("filter.common.sort_by_status") }}
                    </option>
                    <option value="G.fit_goods_nm">
                      {{ $t("filter.common.sort_by_goods_name") }}
                    </option>
                  </select>
                  <select aria-label="" v-model="searchData.pagesize">
                    <option value="10">
                      {{ $t("filter.common.sort_by_10") }}
                    </option>
                    <option value="20">
                      {{ $t("filter.common.sort_by_20") }}
                    </option>
                    <option value="30">
                      {{ $t("filter.common.sort_by_30") }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </b-form>
          <table class="table board">
            <thead>
              <tr>
                <th class="col-auto d-none d-lg-table-cell">
                  {{ $t("title.common.no") }}
                </th>
                <th class="col-auto d-lg-table-cell">
                  {{ $t("title.common.goods_name") }}
                </th>
                <th class="col-auto d-none d-lg-table-cell">
                  {{ $t("title.common.club_information") }}
                </th>
                <th class="col-auto d-lg-table-cell w-1 text-center">
                  {{ $t("title.common.status") }}
                </th>
                <th class="col-auto d-none d-lg-table-cell">
                  {{ $t("title.common.nickname") }}
                </th>
                <th class="col-auto d-none d-lg-table-cell">
                  {{ $t("title.common.location") }}
                </th>
                <th class="col-auto d-none d-lg-table-cell">
                  {{ $t("title.common.date") }}
                </th>
                <th class="th-arrow">&nbsp;</th>
              </tr>
            </thead>
            <tbody v-if="total_cnt">
              <template v-for="(item, index) in items.fittinginfo">
                <tr :key="index">
                  <td class="col-auto d-none d-lg-table-cell">
                    {{ item.no }}
                  </td>
                  <td class="col-auto d-lg-table-cell text-start">
                    <router-link
                      :to="{
                        name: 'FittingSwingList',
                        params: {
                          fittingno: item.fittingno,
                        },
                        query: { goodstype: searchData.goodstype },
                      }"
                    >
                      <p class="goods-kind">
                        {{ item.goodstype | get_club_type }}({{
                          item.clubtype_code_name
                        }})
                      </p>
                      <p class="goods-name">{{ item.goodsname }}</p>
                    </router-link>
                  </td>
                  <td class="col-auto d-none d-lg-table-cell text-start">
                    <router-link
                      :to="{
                        name: 'FittingSwingList',
                        params: {
                          fittingno: item.fittingno,
                        },
                        query: { goodstype: searchData.goodstype },
                      }"
                    >
                      {{ $t("page.goods.shaft") }}({{ item.shaftname }} /
                      {{ item.shaftmaterial | get_shaft_material }}
                      /
                      {{ item.shaftflextype | get_balance }})<br />
                      {{ $t("title.common.loft") }} ({{
                        item.goodsloft || "-"
                      }}) {{ $t("title.common.lie") }} ({{
                        item.goodslie || "-"
                      }}) {{ $t("title.common.sole") }} ({{
                        item.goodssole || "-"
                      }}) {{ $t("title.common.fp") }} ({{
                        item.goodsfp || "-"
                      }}) {{ $t("title.common.length") }} ({{
                        item.goodslength || "-"
                      }}) {{ $t("title.common.weight") }} ({{
                        item.goodsweight || "-"
                      }})
                    </router-link>
                  </td>
                  <td class="col-auto d-lg-table-cell text-center">
                    <span
                      v-if="item.fittingstatus != '0'"
                      class="badge bg-secondary bg-opacity-50"
                      >{{ $t("title.main.fitting_done") }}</span
                    >
                    <span v-else class="badge bg-orange">{{
                      $t("title.main.fitting_ready")
                    }}</span>
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    <MemberInfo
                      :nickname="item.nickname"
                      :memberno="item.memberno"
                    />
                  </td>
                  <td class="col-auto d-none d-lg-table-cell shop-name">
                    {{ item.shopname }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell text-date">
                    {{ item.timezone_regdate | dateformat }}
                  </td>
                  <td class="td-arrow" @click="toggle_tr(index)">
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="col-auto d-lg-none"
                  :class="{ show: view_hide === index }"
                  :key="index + 'r'"
                  v-if="view_hide === index"
                >
                  <td colspan="7">
                    <div class="toggle-content">
                      <div class="row">
                        <div class="col-4">{{ $t("title.common.no") }}</div>
                        <div class="col-8">{{ item.no }}</div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.club_information") }}
                        </div>
                        <div class="col-8">
                          <router-link
                            :to="{
                              name: 'FittingSwingList',
                              params: {
                                fittingno: item.fittingno,
                              },
                              query: { goodstype: searchData.goodstype },
                            }"
                          >
                            {{ $t("page.goods.shaft") }}({{ item.shaftname }} /
                            {{ item.shaftmaterial | get_shaft_material }}
                            /
                            {{ item.shaftflextype | get_balance }})<br />
                            {{ $t("title.common.loft") }} ({{
                              item.goodsloft || "-"
                            }}) {{ $t("title.common.lie") }} ({{
                              item.goodslie || "-"
                            }}) {{ $t("title.common.sole") }} ({{
                              item.goodssole || "-"
                            }}) {{ $t("title.common.fp") }} ({{
                              item.goodsfp || "-"
                            }}) {{ $t("title.common.length") }} ({{
                              item.goodslength || "-"
                            }}) {{ $t("title.common.weight") }} ({{
                              item.goodsweight || "-"
                            }})
                          </router-link>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.nickname") }}
                        </div>
                        <div class="col-8">
                          <MemberInfo
                            :nickname="item.nickname"
                            :memberno="item.memberno"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.location") }}
                        </div>
                        <div class="col-8 shop-name">{{ item.shopname }}</div>
                      </div>
                      <div class="row">
                        <div class="col-4">{{ $t("title.common.date") }}</div>
                        <div class="col-8 text-date">
                          {{ item.timezone_regdate | dateformat }}
                        </div>
                      </div>
                    </div>
                    <!-- //toggle-content -->
                  </td>
                </tr>
              </template>
              <tr v-if="!items.fittinginfo.length">
                <td colspan="7" class="text-center">
                  {{ $t("text.common.no_data") }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7" class="text-center">
                  {{ $t("text.common.no_data") }}
                </td>
              </tr>
            </tbody>
          </table>
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="page_total"
            v-model="searchData.pageno"
            use-router
            aria-label="Pagination"
            class="mt-3"
            v-if="page_total > 1"
            first-class="prev"
            prev-class="prev"
            next-class="next"
            last-class="next"
            hide-ellipsis
            limit="10"
          >
            <template #first-text>
              <i class="material-icons">keyboard_double_arrow_left</i>
            </template>
            <template #prev-text>
              <i class="material-icons">navigate_before</i>
            </template>
            <template #next-text>
              <i class="material-icons">navigate_next</i>
            </template>
            <template #last-text>
              <i class="material-icons">keyboard_double_arrow_right</i>
            </template>
            <template #page="{ page, active }">
              <a v-if="active">{{ page }}</a>
              <a v-else>{{ page }}</a>
            </template>
          </b-pagination-nav>
        <!-- </div> -->
        <!-- //tab-pane -->
      <!-- </div> -->
      <!-- //tab-content -->
    </div>
    <!-- //page -->
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/fitting/ListTabMain";
import ax from "@/api/fitting";
import "@/filter/common";

export default {
  name: "FittingList",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "G.fit_goods_nm",
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        swingno: 0,
        goodstype:
          this.$route.query.goodstype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].goodstype) ||
          0,
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
        pagesize: 10,
        findex: "R.reg_date",
      },

      page_total: 1,
      total_cnt: 0,
      items: { fittinginfo: [] },
      view_hide: null,
      deliverySendData: { info: {}, shiplist: [] },
    };
  },
  methods: {
    get_fittinglist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });

      ax.get_fittinglist(this.searchData, (flag, data = "") => {
        console.log(flag, data);
        this.total_cnt = data.pagination.fittinglistcnt;
        this.page_total = Math.ceil(
          data.pagination.fittinglistcnt / this.searchData.pagesize
        );
        this.items = data.fittinglist;

        if (!flag) alert(data.message);
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.get_fittinglist();
      // this.$store.commit("setSearchData", {
      //   [this.$route.name]: this.searchData,
      // });
      // this.get_fittinglist();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },

    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_fittinglist();
  },
  watch: {
    "$route.query.goodstype": function () {
      (this.searchData.goodstype = this.$route.query.goodstype || "0"),
        (this.searchData.pageno = 1);
      this.view_hide = null;
      this.get_fittinglist();
    },
    "searchData.searchtype": function () {
      this.searchData.searchname = "";
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_fittinglist();
    },
    "searchData.findex": function () {
      this.searchData.pageno = 1;
      this.get_fittinglist();
    },
    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.get_fittinglist();
    },
    "searchData.pageno": function () {
      this.get_fittinglist();
    },
  },
};
</script>