<template>
  <div class="tab-main">
    <swiper
      :options="{
        slidesPerView: 'auto',
        freeMode: true,
        initialSlide: tab - 1,
      }"
      class="swipe"
    >
      <swiper-slide :class="{ active: tab == 1 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsMain',
                params: { goodstype: '1' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == 'StatsMain',
          }"
        >
          {{ $t("button.stats.main") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 2 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsSaleTotal',
                params: { goodstype: '2' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == 'StatsSaleTotal',
          }"
        >
          {{ $t("button.stats.total_sales_sum") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 3 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsSaleClub',
                params: { goodstype: '3' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == 'StatsSaleClub',
          }"
        >
          {{ $t("button.stats.sales_sum_by_club") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 4 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsSaleModel',
                params: { goodstype: '4' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == 'StatsSaleModel',
          }"
        >
          {{ $t("button.stats.sales_sum_by_goods_type") }}
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 5 }">
        <div
          @click="
            $router
              .push({
                name: 'StatsSaleShop',
                params: { goodstype: '5' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype == 'StatsSaleShop',
          }"
        >
          {{ $t("button.stats.sales_sum_by_location") }}
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "statsmainTab",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /*swiperOptions: {
        slidesPerView: 'auto',
        freeMode: true
      }*/
    };
  },
  computed: {
    tab: function () {
      if (this.$route.name === "StatsMain") {
        // alert(this.$route.params.goodstype);
        if (this.$route.params.goodstype) {
          return parseInt(this.$route.params.goodstype);
        } else {
          return 1;
        }
      } else if (this.$route.name === "StatsSaleTotal") {
        return 2;
      } else if (this.$route.name === "StatsSaleClub") {
        return 3;
      } else if (this.$route.name === "StatsSaleModel") {
        return 4;
      } else if (this.$route.name === "StatsSaleShop") {
        return 5;
      } else {
        return 1;
      }
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>