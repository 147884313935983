<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page">
      <div
        class="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 g-lg-4"
        v-if="data_yn"
      >
        <div class="col" :key="index" v-for="(item, index) in adlist">
          <router-link
            :to="{
              name: 'AdWrite',
              params: {
                adno: item.adno,
              },
              query: {
                adtype: searchData.adtype,
              },
            }"
            class="card h-100 rounded-0 view-zoom-in"
          >
            <div
              class="card-body d-flex justify-content-center align-items-center overflow-hidden m-2 p-0"
            >
              <img :src="ad_img(index)" class="rounded-0" />
            </div>
            <div class="card-footer p-3 border-0 bg-light">
              <h6 class="mb-0 font-montserrat text-truncate">
                {{ item.adtitle }}
              </h6>
            </div>
          </router-link>
        </div>
      </div>

      <div class="row g-0 border-top-dgray border-bottom text-center" v-else>
        <div class="col m-5">{{ $t("text.common.no_data") }}</div>
      </div>

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Pagination"
        v-if="page_total > 1 && data_yn"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
    <!-- //page -->
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/ad/Tab";
import ax from "@/api/ad";
import "@/filter/common";

export default {
  name: "AdList",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        goodstype: this.$route.params.goodstype || "0",
        adtype: this.$route.params.adtype || 1,
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      adlist: [],
      rows: 20,
      adlistcnt: 0,
    };
  },
  methods: {
    getAdlist() {
      (this.searchData.goodstype =
        this.$route.params.goodstype ||
        (this.$store.state.searchData[this.$route.name] &&
          this.$store.state.searchData[this.$route.name].goodstype) ||
        "0"),
        this.$store.commit("setSearchData", {
          [this.$route.name]: this.searchData,
        });
      this.searchData.adtype = this.$route.meta.adtype || "";
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });

      ax.get_adlist(this.searchData, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          if (data.pagination.adlistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }

          this.adlist = data.adlist;
          this.adlistcnt = data.pagination.adlistcnt;
          this.total_cnt = data.pagination.adlistcnt;
          this.page_total = Math.ceil(data.pagination.adlistcnt / this.rows);
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      //event.preventDefault();
      this.searchData.pageno = 1;
      this.$router.push({
        query: {
          searchtype: this.searchData.searchtype,
          searchname: this.searchData.searchname,
          goodstype: this.searchData.goodstype,
          adtype: this.searchData.adtype,
          brandno: this.searchData.brandno,
        },
      });
    },
    linkGen(pageNum) {
      return `?page=${pageNum}&goodstype=${this.searchData.goodstype}&adtype=${this.searchData.adtype}`;
    },
    ad_img(index) {
      if (
        this.adlist[index].adimagepath !== null &&
        this.adlist[index].adimagepath !== ""
      ) {
        //   return this.adlist[index].path + "/" + this.adlist[index].adimagepath;
        return (
          process.env.VUE_APP_S3_BUCKET_URL +
          "/" +
          this.adlist[index].adimagepath
        );
      } else {
        return "/img/no-img.jpg";
      }
    },
  },
  created() {
    this.getAdlist();
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      this.getAdlist();
    },
    "searchData.goodstype": function () {
      this.searchData.pageno = 1;
      this.onSubmit();
    },
    "searchData.brandno": function () {
      this.searchData.pageno = 1;
      this.getAdlist();
    },
  },
  computed: {
    getGoodsType: function () {
      return this.$route.params.goodstype || "0";
    },
    get_name: function () {
      return this.$route.name || "";
    },
  },
};
</script>