<template>
  <div class="container-fluid">
    <location />
    <div class="page shop">
      <b-form @submit="onSubmit">
        <div class="filter-group row">
          <div class="col-sm-auto mb-2 mb-sm-0 col-sm-6 col-md-5">
            <div class="d-flex">
              <select
                class="w-auto"
                aria-label=" "
                name="searchtype"
                v-model="searchData.searchtype"
              >
                <option value="1">{{ $t("filter.common.location") }}</option>
                <option value="2">
                  {{ $t("filter.common.phone_number") }}
                </option>
              </select>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control text-truncate"
                  :placeholder="$t('text.common.input_search')"
                  :aria-label="$t('text.common.input_search')"
                  aria-describedby="button-addon"
                  name="searchname"
                  v-model="searchData.searchname"
                />
                <button
                  class="btn btn-primary btn-search"
                  type="submit"
                  id="button-addon"
                ></button>
              </div>
            </div>
          </div>

          <div class="col-sm-auto ms-sm-auto col-sm-5 col-md-4">
            <div class="d-flex">
              <select aria-label="" v-model="searchData.findex">
                <option value="S.reg_date" selected>
                  {{ $t("filter.common.sort_by_date") }}
                </option>
                <!-- <option value="P.nickname">제품명순</option> -->
                <option value="O.nickname">
                  {{ $t("filter.common.sort_by_location") }}
                </option>
              </select>
              <select aria-label="" v-model="searchData.pagesize">
                <option value="10" selected>
                  {{ $t("filter.common.sort_by_10") }}
                </option>
                <option value="20">{{ $t("filter.common.sort_by_20") }}</option>
                <option value="30">{{ $t("filter.common.sort_by_30") }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="mb-3 pt-1">
          {{ $t("page.common.total")
          }}<b class="text-primary"> {{ shoplistcnt }}</b>
          {{ $t("text.common.ea") }}
        </div>
      </b-form>

      <table class="table board">
        <thead>
          <tr>
            <th class="col-auto d-lg-table-cell w-1">
              <input
                type="checkbox"
                class="m-0"
                v-model="allSelected"
                @click="selectAll()"
              />
            </th>
            <th class="col-auto d-lg-table-cell">
              {{ $t("title.member.location") }}
            </th>
            <!--
            <th class="col-auto d-lg-table-cell" v-if="admin_login">
              브랜드명
            </th>
            -->
            <th class="col-auto d-none d-md-table-cell">
              {{ $t("page.location.basic_information") }}
            </th>
            <th class="col-auto d-lg-table-cell w-1">
              {{ $t("title.location.room_no") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell text-nowrap">
              {{ $t("title.location.member_count") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell text-nowrap">
              {{ $t("title.location.fitting_count") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.location.sales_sum") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in shoplist">
            <tr :key="index">
              <td class="col-auto d-lg-table-cell w-1">
                <input
                  type="checkbox"
                  class="m-0"
                  :value="index"
                  v-model="checkedShopidx"
                  @change="updateCheckall()"
                />
              </td>
              <td class="col-auto d-lg-table-cell text-start">
                <ShopInfo
                  :shoptitle="item.shoptitle"
                  :shopno="item.shopno"
                  :brandno="searchData.brandno"
                  class="shop-name"
                />
                <div v-if="admin_login">
                  <b-button
                    size="xs"
                    variant="primary"
                    :aria-label="$t('button.common.club_write')"
                    @click="BrandGoodsModalOpen(item.shopno, item.shoptitle)"
                    class="mt-2"
                    >{{ $t("button.common.club_write") }}</b-button
                  >
                </div>
              </td>
              <!-- <td
                class="col-auto d-lg-table-cell brand-name"
                v-if="admin_login"
              >
                {{ item.brandname }}
              </td> -->
              <td class="col-auto d-none d-md-table-cell text-start">
                <div class="basic-info">
                  <div class="d-flex">
                    <span class="material-icons-outlined">place</span
                    >{{ item.shopaddress }}
                  </div>

                  <div class="row my-2">
                    <div class="col-auto">
                      <span class="material-icons-outlined">phone</span
                      >{{ item.shopphone }}
                    </div>
                    <div class="col-auto">
                      <span class="material-icons-outlined">print</span
                      >{{ item.shopfax }}
                    </div>
                  </div>

                  <div class="d-flex">
                    <span class="material-icons-outlined">email</span
                    >{{ item.shopemail }}
                  </div>
                </div>
              </td>
              <td class="col-auto d-lg-table-cell">
                <b-dropdown
                  id="dropdown-1"
                  size="sm"
                  :text="$t('title.common.choose')"
                  variant="text border text-black"
                  menu-class="min-w-auto fs-14"
                  v-if="shoplist[index].shoproomlist"
                >
                  <template
                    v-for="(item1, index1) in shoplist[index].shoproomlist"
                  >
                    <b-dropdown-item :key="'a' + index1">
                      <RoomInfo
                        :shopno="item.shopno"
                        :shoptitle="item.shoptitle"
                        :roomno="item1.roomno"
                        :roomname="item1.aliasname"
                        :brandno="item1.fit_brand_fk"
                        :brandname="item1.fit_brand_nm"
                        @getshoplist="getShoplist"
                      />
                    </b-dropdown-item>
                  </template>
                </b-dropdown>

                <!--                 <div class="row">
                  <div
                    class="col"
                    style="border: 1px solid #ffffff; background-color: #dddddd"
                    v-for="(item1, index1) in shoplist[index].shoproomlist"
                    :key="index1"
                  >
                    <RoomInfo
                      :shopno="item.shopno"
                      :shoptitle="item.shoptitle"
                      :roomno="item1.roomno"
                      :brandno="item.brandno"
                      :brandname="item.brandname"
                      :getmethod="getShoplist"
                    />
                  </div>
                </div> -->
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                <h5 class="text-nowrap fw-semibold text-cyan">
                  {{ item.shopmembercnt
                  }}<small class="text-cyan">{{
                    $t("text.common.people")
                  }}</small>
                </h5>
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                <h5 class="text-nowrap fw-semibold text-purple">
                  {{ item.shopfittingcnt
                  }}<small class="text-purple">{{
                    $t("text.common.cnt")
                  }}</small>
                </h5>
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                <h5 class="text-nowrap fw-semibold text-price">
                  {{ item.shopsalesum | comma("-")
                  }}<small class="text-price" v-if="item.shopsalesum">{{
                    $t("text.common.unit_1000")
                  }}</small>
                </h5>
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="d-lg-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="8">
                <div class="toggle-content">
                  <!--
                  <div class="row" v-if="admin_login">
                    <div class="col-4 brand-name">브랜드명</div>
                    <div class="col-8">{{ item.brandname }}</div>
                  </div>
                   -->
                  <div class="row d-md-none">
                    <div class="col-4">
                      {{ $t("title.location.address_location") }}
                    </div>
                    <div class="col-8">{{ item.shopaddress }}</div>
                  </div>
                  <div class="row d-md-none">
                    <div class="col-4">{{ $t("title.common.phone") }}</div>
                    <div class="col-8">{{ item.shophpone }}</div>
                  </div>
                  <div class="row d-md-none">
                    <div class="col-4">{{ $t("title.location.fax") }}</div>
                    <div class="col-8">{{ item.shopfax }}</div>
                  </div>
                  <div class="row d-md-none">
                    <div class="col-4">{{ $t("title.location.email") }}</div>
                    <div class="col-8">{{ item.shopemail }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.location.member_count") }}
                    </div>
                    <div class="col-8">
                      <h5 class="fw-semibold text-cyan">
                        {{ item.shopmembercnt
                        }}<small class="text-cyan">{{
                          $t("text.common.peoples")
                        }}</small>
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.location.fitting_count") }}
                    </div>
                    <div class="col-8">
                      <h5 class="fw-semibold text-purple">
                        {{ item.shopfittingcnt
                        }}<small class="text-purple">{{
                          $t("text.common.cnt")
                        }}</small>
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.location.sales_sum") }}
                    </div>
                    <div class="col-8">
                      <h5 class="fw-semibold text-price">
                        {{ item.shopsalesum
                        }}<small class="text-price">{{
                          $t("text.common.unit_1000")
                        }}</small>
                      </h5>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">{{ $t("text.common.no_data") }}</td>
          </tr>
        </tbody>
      </table>
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation example"
        class="mt-3"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>

      <div class="btn-bottom-wrapper" v-if="admin_login">
        <b-button
          size="xl"
          variant="outline-secondary"
          :aria-label="$t('button.common.delete')"
          @click="shopDel"
          >{{ $t("button.common.delete") }}</b-button
        >
        <b-button
          size="xl"
          variant="secondary"
          :aria-label="$t('button.common.mail_send')"
          @click="shopEmailSend(modal - email - shop)"
          >{{ $t("button.common.mail_send") }}</b-button
        >
        <b-button
          size="xl"
          variant="primary"
          :aria-label="$t('button.common.location_write')"
          @click="BrandShopModalOpen()"
          >{{ $t("button.common.location_write") }}</b-button
        >
      </div>

      <div class="btn-bottom-wrapper" v-else>
        <b-button
          size="xl"
          variant="outline-primary"
          :aria-label="$t('button.common.delete')"
          @click="shopDel"
          >{{ $t("button.common.delete") }}</b-button
        >
        <b-button
          size="xl"
          variant="primary"
          :aria-label="$t('button.common.mail_send')"
          @click="shopEmailSend(modal - email - shop)"
          >{{ $t("button.common.mail_send") }}</b-button
        >
      </div>

      <b-modal
        id="modal-email-shop"
        size="lg"
        centered
        scrollable
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">{{ $t("title.common.mail_send") }}</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <div class="row g-0 table-type header-col border-1">
            <div class="col-sm-3">
              <span class="required">{{ $t("title.location.sender") }}</span>
            </div>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                :placeholder="$t('alert.common.input_sender')"
                readonly
                :value="checkedManagerEmail"
              />
            </div>

            <div class="col-sm-3">
              <span class="required">{{ $t("title.location.receiver") }}</span>
            </div>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                :placeholder="$t('alert.common.input_receiver')"
                :value="checkedShopEmail"
              />
            </div>

            <div class="col-sm-3">
              <span class="required">{{ $t("title.location.title") }}</span>
            </div>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                :placeholder="$t('alert.common.enter_title')"
              />
            </div>

            <div class="col-sm-3">
              <span class="required">{{ $t("title.common.content") }}</span>
            </div>
            <div class="col-sm-9">
              <b-form-textarea
                :placeholder="$t('alert.common.enter_content')"
                class="mb-2"
                rows="10"
              ></b-form-textarea>
            </div>
          </div>
        </template>

        <template #modal-footer="{}">
          <b-button
            size="md"
            variant="outline-secondary"
            :aria-label="$t('button.common.cancel')"
            @click="$bvModal.hide('modal-email-shop')"
          >
            {{ $t("button.common.cancel") }}
          </b-button>
          <b-button
            size="md"
            variant="secondary"
            :aria-label="$t('button.common.mail_send')"
            @click="sendEmail"
          >
            {{ $t("button.common.mail_send") }}
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="modal-register-shop"
        ref="modal-register-shop"
        size="md"
        centered
        scrollable
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">{{ $t("title.common.location_write") }}</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <b-form>
            <div class="row g-0 table-type header-col border-1">
              <div class="col-sm-3">
                <span>{{ $t("title.common.brand_name") }}</span>
              </div>
              <div class="col-sm-9">
                <select
                  :aria-label="$t('title.common.choose_brand')"
                  v-model="shopRegisterData.searchshopbrandno"
                >
                  <option value="0">
                    {{ $t("title.common.choose_brand") }}
                  </option>
                  <option
                    :value="br.brandno"
                    v-for="(br, index) in shopRegisterData.searchshopbrandlist"
                    :key="'b' + index"
                  >
                    {{ br.brandname }}
                  </option>
                </select>
              </div>

              <div class="col-sm-3">
                <span>{{ $t("title.location.location_search") }}</span>
              </div>
              <div class="col-sm-9">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :aria-label="$t('alert.common.enter_location_name')"
                    v-model="shopRegisterData.searchshopname"
                  />
                  <button
                    class="btn btn-primary btn-search"
                    type="button"
                    id="button-addon"
                    @click="getSearchshoplist()"
                  ></button>
                </div>
              </div>

              <div class="col-sm-3" v-if="data_yn_register_shop">
                <span>{{ $t("title.location.location_choose") }}</span>
              </div>
              <div class="col-sm-9" v-if="data_yn_register_shop">
                <select
                  :aria-label="$t('title.location.location_choose')"
                  v-model="shopRegisterData.searchshopno"
                >
                  <option value="0">
                    {{ $t("title.location.location_choose") }}
                  </option>
                  <option
                    :value="br.shopno"
                    v-for="(br, index) in shopRegisterData.searchshoplist"
                    :key="'c' + index"
                  >
                    {{ br.shopname }}
                  </option>
                </select>
              </div>
            </div>
          </b-form>
        </template>

        <template #modal-footer="{}">
          <b-button
            size="md"
            variant="outline-secondary"
            :aria-label="$t('button.common.cancel')"
            @click="$bvModal.hide('modal-register-shop')"
          >
            {{ $t("button.common.cancel") }}
          </b-button>
          <b-button
            size="md"
            variant="secondary"
            :aria-label="$t('button.common.write')"
            @click="setShopRegister()"
          >
            {{ $t("button.common.write") }}
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="modal-register-club"
        ref="modal-register-club"
        size="lg"
        centered
        scrollable
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">{{ $t("button.common.club_write") }}</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <div
            class="filter-group row row-cols-1 row-cols-sm-auto align-items-center"
          >
            <div class="col">
              <h6 class="text-capitalize mb-3 mb-sm-0">
                {{ shopGoodsRegisterData.goodsshopname }}
                {{ shopGoodsRegisterData.form.checkedgoods }}
              </h6>
            </div>
            <div class="col ms-auto bg-teal">
              <div class="d-flex">
                <select
                  :aria-label="$t('title.common.choose_brand')"
                  v-model="shopGoodsRegisterData.form.goodsbrandno"
                  class="w-auto"
                >
                  <option value="0">
                    {{ $t("title.common.choose_brand") }}
                  </option>
                  <option
                    :value="br.brandno"
                    v-for="(br, index) in shopGoodsRegisterData.roombrandlist"
                    :key="'d' + index"
                  >
                    {{ br.brandname }}
                  </option>
                </select>

                <div class="input-group">
                  <select
                    :aria-label="$t('title.location.room_choose')"
                    aria-describedby="button-addon"
                    id="button-addon"
                    class="form-select"
                    v-model="shopGoodsRegisterData.form.goodsroomno"
                  >
                    <option value="0">
                      {{ $t("title.location.room_choose") }}
                    </option>
                    <option
                      :value="rm.roomno"
                      v-for="(rm, index) in shopGoodsRegisterData.roomlist"
                      :key="'e' + index"
                    >
                      {{ rm.roomname }}
                    </option>
                  </select>
                  <button
                    class="btn btn-primary btn-search"
                    type="button"
                    id="button-addon"
                    :aria-label="$t('button.common.search')"
                    @click="getBrandGoodsInfoSearch()"
                  ></button>
                </div>
              </div>
            </div>
          </div>

          <table class="table board table-sm">
            <thead>
              <tr>
                <th class="w-1 border-end">
                  {{ $t("title.common.club_type") }}
                </th>
                <th>
                  {{ $t("title.location.detail") }}
                </th>
              </tr>
            </thead>
            <tbody
              v-if="
                shopGoodsRegisterData.brandgoodslist.length ||
                roomgoodslist_.length
              "
            >
              <!-- 보유장비 있는 경우 -->

              <tr class="current-use-club" v-if="roomgoodslist_.length">
                <th colspan="2" class="fw-medium text-black">
                  {{ $t("title.member.current_use_club") }}
                </th>
              </tr>
              <template v-for="(item1, index1) in roomgoodslist_">
                <tr class="current-use-club" :key="'r' + index1">
                  <td class="border-end goods-kind">
                    {{ club_type(item1.roomgoodstype) }}
                  </td>
                  <td class="p-0 goods-details">
                    <template
                      v-for="(item3, index3) in roomgoodslist.filter(
                        (v) =>
                          v.roomno == item1.roomno &&
                          v.roomgoodstype == item1.roomgoodstype
                      )"
                    >
                      <template
                        v-for="(item2, index2) in item3.roomgoodsinfodetail"
                      >
                        <table
                          v-if="index2 == 0"
                          :key="'a' + index1 + index2 + index3"
                        >
                          <tr v-if="index2 == 0" :key="'a' + index2">
                            <td class="w-1 pe-0 d-none d-sm-table-cell">
                              <img
                                :src="item3.roomgoodsimage | get_img"
                                class="img-thumbnail"
                              />
                            </td>
                            <td class="text-start">
                              <p class="small">
                                <span class="brand-name">{{
                                  item3.fit_brand_nm
                                }}</span>
                              </p>

                              <p class="goods-name lh-sm">
                                {{ item3.roomgoodsname }}
                                {{ item2.clubtypename }}
                              </p>
                              <p class="mt-1 text-subscript">
                                {{ $t("title.common.loft") }}
                                {{ item2.clubloft }}&deg;<span
                                  class="mx-1 text-black text-opacity-25"
                                  >|</span
                                >{{ $t("title.common.strength") }}
                                {{ item3.roomgoodsbalance }}
                              </p>
                            </td>
                            <td>
                              <b-button
                                v-if="item3.roomgoodscnt > 1"
                                size="xs"
                                variant="bundle bg-primary"
                                :aria-labe="$t('button.common.more')"
                                :class="{
                                  show:
                                    view_hide_shop_club ===
                                    'a' + index1 + index3 + item3.roomgoodsno,
                                }"
                                @click="
                                  toggle_shop_tr(
                                    'a' + index1 + index3 + item3.roomgoodsno
                                  )
                                "
                              ></b-button>
                            </td>
                          </tr>
                        </table>

                        <table v-else :key="'a' + index1 + index2 + index3">
                          <tr
                            :class="{
                              show:
                                view_hide_shop_club ===
                                'a' + index1 + index3 + item3.roomgoodsno,
                            }"
                            v-if="
                              view_hide_shop_club ===
                              'a' + index1 + index3 + item3.roomgoodsno
                            "
                          >
                            <td class="w-1 pe-0 d-none d-sm-table-cell">
                              <img
                                :src="item3.roomgoodsimage | get_img"
                                class="img-thumbnail"
                              />
                            </td>
                            <td class="text-start">
                              <p class="small">
                                <span class="brand-name">{{
                                  item3.fit_brand_nm
                                }}</span>
                              </p>
                              <p class="goods-name lh-sm">
                                {{ item3.roomgoodsname }}
                                {{ item2.clubtypename }}
                              </p>
                              <p class="mt-1 text-subscript">
                                {{ $t("title.common.loft") }}
                                {{ item2.clubloft }}&deg;<span
                                  class="mx-1 text-black text-opacity-25"
                                  >|</span
                                >{{ $t("title.common.strength") }}
                                {{ item3.roomgoodsbalance }}
                              </p>
                            </td>
                            <td></td>
                          </tr>
                        </table>
                      </template>
                    </template>
                  </td>
                </tr>
              </template>
              <!-- 보유장비 없는 경우 -->
              <template
                v-for="(item1, index1) in shopGoodsRegisterData.brandgoodslist_"
              >
                <tr :key="'c' + index1">
                  <td class="border-end goods-kind">
                    {{ club_type(item1.brandgoodstype) }}
                  </td>
                  <td class="p-0 goods-details">
                    <template
                      v-for="(
                        item3, index3
                      ) in shopGoodsRegisterData.brandgoodslist.filter(
                        (v) => v.brandgoodstype == item1.brandgoodstype
                      )"
                    >
                      <template v-for="(item2, index2) in item3.detaillist">
                        <table
                          v-if="index2 == 0"
                          :key="'a' + index1 + index2 + index3"
                        >
                          <tr v-if="index2 == 0" :key="'a' + index2">
                            <td class="w-1 pe-0 d-none d-sm-table-cell">
                              <img
                                :src="item3.brandgoodsimagepath | get_img"
                                class="img-thumbnail"
                              />
                            </td>
                            <td class="text-start">
                              {{ item3.brandgoodsno }}
                              <input
                                :id="item3.brandgoodsno"
                                type="checkbox"
                                class="m-0 mb-1"
                                :checked="isChecked(item3.brandgoodsno)"
                                :value="item3.brandgoodsno"
                                @change="checkName"
                              />
                              <p class="small">
                                <span class="brand-name">{{
                                  item3.fit_brand_nm
                                }}</span>
                              </p>
                              <p class="goods-name lh-sm">
                                {{ item3.fit_goods_nm }}
                                {{ item2.clubtype_code_name }}
                              </p>
                              <p class="text-subscript">
                                {{ $t("title.common.loft") }}
                                {{ item2.fit_club_loft }}&deg;<span
                                  class="mx-1 text-black text-opacity-25"
                                  >|</span
                                >{{ $t("title.common.strength") }}
                                <select
                                  v-model="
                                    shopGoodsRegisterData.form
                                      .brandshaftmaterialcode[
                                      item3.brandgoodsno
                                    ]
                                  "
                                  class="text-type"
                                >
                                  <option value="undefined">
                                    {{ $t("title.common.choose") }}
                                  </option>
                                  <option
                                    :value="item3.fit_balance1"
                                    v-if="item3.fit_balance1 > 0"
                                  >
                                    {{ item3.fit_balance1 | get_balance }}
                                  </option>

                                  <option
                                    :value="item3.fit_balance2"
                                    v-if="item3.fit_balance2 > 0"
                                  >
                                    {{ item3.fit_balance2 | get_balance }}
                                  </option>

                                  <option
                                    :value="item3.fit_balance3"
                                    v-if="item3.fit_balance3 > 0"
                                  >
                                    {{ item3.fit_balance3 | get_balance }}
                                  </option>

                                  <option
                                    :value="item3.fit_balance4"
                                    v-if="item3.fit_balance4 > 0"
                                  >
                                    {{ item3.fit_balance4 | get_balance }}
                                  </option>
                                  <option
                                    :value="item3.fit_balance5"
                                    v-if="item3.fit_balance5 > 0"
                                  >
                                    {{ item3.fit_balance5 | get_balance }}
                                  </option>
                                </select>
                              </p>
                            </td>
                            <td>
                              <b-button
                                v-if="item3.detailcnt > 1"
                                size="xs"
                                variant="bundle bg-primary"
                                :aria-labe="$t('button.common.more')"
                                :class="{
                                  show:
                                    view_hide_room_club ===
                                    'a' + index1 + index3 + item3.brandgoodsno,
                                }"
                                @click="
                                  toggle_club_tr(
                                    'a' + index1 + index3 + item3.brandgoodsno
                                  )
                                "
                              ></b-button>
                            </td>
                          </tr>
                        </table>

                        <table v-else :key="'a' + index1 + index2 + index3">
                          <tr
                            class="bg-sky"
                            :class="{
                              show:
                                view_hide_room_club ===
                                'a' + index1 + index3 + item3.brandgoodsno,
                            }"
                            v-if="
                              view_hide_room_club ===
                              'a' + index1 + index3 + item3.brandgoodsno
                            "
                          >
                            <td class="w-1 pe-0 d-none d-sm-table-cell">
                              <img
                                :src="item3.brandgoodsimagepath | get_img"
                                class="img-thumbnail"
                              />
                            </td>
                            <td class="text-start">
                              <p class="small">
                                <span class="brand-name">
                                  {{ item1.fit_brand_nm }}
                                </span>
                              </p>
                              <p class="goods-name lh-sm">
                                {{ item3.fit_goods_nm }}
                                {{ item2.clubtype_code_name }}
                              </p>
                              <p class="text-subscript">
                                {{ $t("title.common.loft") }}
                                {{ item2.fit_club_loft }} &deg;<span
                                  class="mx-1 text-black text-opacity-25"
                                  >|</span
                                >{{ $t("title.common.strength") }}
                                <select
                                  v-model="
                                    shopGoodsRegisterData.form
                                      .brandshaftmaterialcode[
                                      item3.brandgoodsno
                                    ]
                                  "
                                  class="text-type"
                                >
                                  <option value="undefined">
                                    {{ $t("title.common.choose") }}
                                  </option>
                                  <option
                                    :value="item3.fit_balance1"
                                    v-if="item3.fit_balance1 > 0"
                                  >
                                    {{ item3.fit_balance1 | get_balance }}
                                  </option>

                                  <option
                                    :value="item3.fit_balance2"
                                    v-if="item3.fit_balance2 > 0"
                                  >
                                    {{ item3.fit_balance2 | get_balance }}
                                  </option>

                                  <option
                                    :value="item3.fit_balance3"
                                    v-if="item3.fit_balance3 > 0"
                                  >
                                    {{ item3.fit_balance3 | get_balance }}
                                  </option>

                                  <option
                                    :value="item3.fit_balance4"
                                    v-if="item3.fit_balance4 > 0"
                                  >
                                    {{ item3.fit_balance4 | get_balance }}
                                  </option>
                                  <option
                                    :value="item3.fit_balance5"
                                    v-if="item3.fit_balance5 > 0"
                                  >
                                    {{ item3.fit_balance5 | get_balance }}
                                  </option>
                                </select>
                              </p>
                            </td>
                            <td></td>
                          </tr>
                        </table> </template
                    ></template>
                  </td>
                </tr>
              </template>
            </tbody>

            <tbody v-else>
              <tr>
                <td colspan="2">{{ $t("text.common.no_data") }}</td>
              </tr>
            </tbody>
          </table>
        </template>
        <template #modal-footer="{}">
          <b-button
            size="md"
            variant="outline-secondary"
            :aria-label="$t('button.common.cancel')"
            @click="$bvModal.hide('modal-register-club')"
          >
            {{ $t("button.common.cancel") }}
          </b-button>
          <b-button
            size="md"
            variant="secondary"
            :aria-label="$t('button.common.write')"
            @click="setShopRoomRegister()"
          >
            {{ $t("button.common.write") }}
          </b-button>
        </template>
      </b-modal>
    </div>
    <!-- //page -->
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/shop";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "Stores",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          1,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        findex:
          this.$route.query.findex ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].findex) ||
          "S.reg_date",
        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pagesize) ||
          10,
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || "0",
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      shoplist: [],
      shoproomlist: [],
      rows: 10,
      shoplistcnt: 0,
      checkedShopidx: [],
      allSelected: false,
      checkedShopEmail: [],
      checkedManagerEmail: "",
      sendData: {
        title: "",
        content: "",
        shopno: [],
        email: [],
      },
      view_hide: null,
      view_hide_room: null,
      view_hide_room_club: null,
      view_hide_use: null,
      data_yn_register_shop: false,
      view_hide_shop_club: null,

      shopRegisterData: {
        searchshopbrandlist: [],
        searchshoplist: [],
        searchshopbrandno: 0,
        searchshopname: "",
        searchshopno: 0,
      },

      shopGoodsRegisterData: {
        goods_total_cnt: 0,
        brandgoodslist: [],
        brandgoodslist_: [],
        allGoodsSelected: false,
        goodsshopname: "",
        roombrandlist: [],
        roomlist: [],
        checkedGoodsidxlength: 0,
        form: {
          goodsbrandno: 0,
          goodsshopno: 0,
          goodsroomno: 0,
          brandgoodsno: [],
          checkedGoodsidx: [],
          checkedgoods: [],
          brandshaftmaterialcode: {},
          brandgoodscnt: 0,
        },
      },
      sendData: { fit_shop_pk: [] },
      roomno_: [],
      roomgoodslist: [],
      roomgoodslist_: [],
      roomgoodsinfodetail: [],
      isChildmodal: true,
      admin_login: sessionStorage.getItem("isAdmin") == "1" ? true : false,
    };
  },
  methods: {
    getRoomInfo(shopno, idx) {
      if (this.roomno_[idx] != "undefined")
        this.$refs.apiRequest[idx].getRoomInfo(shopno, this.roomno_[idx]);
    },
    getShoplist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_shoplist(this.searchData, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          if (data.pagination.shoplistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.shoplist = data.shoplist;

          for (let i = 0; i < data.shoplist.length; i++) {
            if (data.shoplist[i].shoproomlist) {
              for (let j = 0; j < data.shoplist[i].shoproomlist.length; j++) {
                this.shoplist[i].shoproomlist[j] =
                  data.shoplist[i].shoproomlist[j];
                // alert(data.shoplist[0].shoproomlist[i].roomno);
              }
            }
          }

          this.shoplistcnt = data.pagination.shoplistcnt;
          this.total_cnt = data.pagination.shoplistcnt;
          this.page_total = Math.ceil(data.pagination.shoplistcnt / this.rows);
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    getBrandlist() {
      ax.get_brandlist((flag, data) => {
        // console.log(flag, data);
        if (flag) {
          this.shopRegisterData.searchshopbrandlist = data.brandlist;
        } else {
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },

    getRoomBrandlist(shopno) {
      ax.get_roombrandlist(shopno, (flag, data) => {
        // console.log(flag, data);
        if (flag) {
          this.shopGoodsRegisterData.roombrandlist = data.brandlist;
        } else {
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    getShopRoomlist(shopno) {
      ax.get_shoproomlist(shopno, (flag, data) => {
        // console.log(flag, data);
        if (flag) {
          // this.shopGoodsRegisterData.roomlistcnt = data.roomlist.length;
          this.shopGoodsRegisterData.roomlist = data.roomlist;
        } else {
          alert(data);
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.getShoplist();
    },
    selectAll() {
      this.checkedShopidx = [];
      this.checkedShopEmail = [];
      // alert(this.allSelected);
      if (!this.allSelected) {
        for (let i = 0; i < this.shoplist.length; i++) {
          //if (this.searchData.pagesize > i) {
          this.checkedShopidx.push(i);
          this.checkedShopEmail.push(
            this.shoplist[this.checkedShopidx[i]].shopemail
          );
          //}
        }
      }
    },
    updateCheckall() {
      // this.allSelected = false;
      // alert(this.checkedShopidx.length);
      if (this.shoplist.length == this.checkedShopidx.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    toggle_room_tr(index) {
      if (this.view_hide_room === index) this.view_hide_room = null;
      else this.view_hide_room = index;
    },
    toggle_club_tr(index) {
      if (this.view_hide_room_club === index) this.view_hide_room_club = null;
      else this.view_hide_room_club = index;
    },

    toggle_use_tr(index) {
      if (this.view_hide_use === index) this.view_hide_use = null;
      else this.view_hide_use = index;
    },
    shopEmailSend() {
      if (this.checkedShopidx.length < 1) {
        /*this.$notify({
          group: 'alert',
          type: 'info',
          title: '정보',
          text: '발송하려는 회원을 선택해주세요.'
        })*/
        alert(this.$i18n.t("alert.common.select_member_to_send"));
      } else {
        this.checkedShopEmail = [];
        for (let i = 0; i < this.checkedShopidx.length; i++) {
          this.checkedShopEmail.push(
            this.shoplist[this.checkedShopidx[i]].shopemail
          );
        }

        this.$bvModal.show("modal-email-shop");
      }
    },
    sendEmail() {
      this.sendData.senderemail = this.checkedManagerEmail;
      this.sendData.email = [];
      this.sendData.shopno = [];
      for (let i = 0; i < this.checkedShopidx.length; i++) {
        this.sendData.email.push(
          this.shoplist[this.checkedShopidx[i]].shopemail
        );
        this.sendData.shopno.push(this.shoplist[this.checkedShopidx[i]].shopno);
      }
      this.sendData.emailcnt = this.checkedShopidx.length;

      if (this.sendData.title.length < 2 || this.sendData.content.length < 2) {
        /*this.$notify({
          group: 'alert',
          type: 'info',
          title: '정보',
          text: '제목 과 내용을 입력해주세요.'
        })*/
        alert(this.$i18n.t("alert.common.input_title_contents"));
        return false;
      } else {
        ax.sendEmail(this.sendData, (flag, response) => {
          if (flag) {
            /*this.$notify({
             group: 'alert',
             type: 'info',
             title: '정보',
              text: '이메일이 발송 되었습니다.'
            })*/
            alert(response.message);
          } else {
            /*this.$notify({
              group: 'alert',
              type: 'info',
              title: '정보',
              text: '발송에 실패하였습니다.'
            })*/
            alert(response.message);
          }
        });
      }
    },
    getSearchshoplist() {
      if (!this.shopRegisterData.searchshopbrandno) {
        alert(this.$i18n.t("alert.common.select_brand3"));
        return false;
      }
      // alert(this.shopRegisterData.searchshopname);
      ax.get_searchshoplist(
        this.shopRegisterData.searchshopname,
        (flag, data) => {
          // console.log(flag, data);
          if (flag) {
            this.data_yn_register_shop = true;
            this.shopRegisterData.searchshoplist = data.searchshoplist;
          } else {
            this.data_yn_register_shop = false;
            alert(data.message);
          }
        }
      );
    },
    setShopRegister() {
      if (!this.shopRegisterData.searchshopbrandno) {
        alert(this.$i18n.t("alert.common.select_brand4"));
        return false;
      }

      if (!this.shopRegisterData.searchshopno) {
        alert(this.$i18n.t("alert.common.select_location"));
        return false;
      }

      ax.set_shopregister(
        this.shopRegisterData.searchshopbrandno,
        this.shopRegisterData.searchshopno,
        (flag, response) => {
          // console.log(flag, response);
          if (flag) {
            alert(response.message);
            this.getShoplist();
            this.$bvModal.hide("modal-register-shop");
            // this.sendData.shopmemo = "";
          } else {
            alert(response.message);
            return false;
          }
        }
      );
    },

    updateGoodsCheckall() {
      // this.allSelected = false;
      // alert(this.shopGoodsRegisterData.brandgoodslist.length);
      this.shopGoodsRegisterData.checkedGoodsidxlength = 0;
      for (
        let i = 0;
        i < this.shopGoodsRegisterData.brandgoodslist.length;
        i++
      ) {
        if (this.shopGoodsRegisterData.form.checkedGoodsidx[i] === true) {
          this.shopGoodsRegisterData.checkedGoodsidxlength =
            this.shopGoodsRegisterData.checkedGoodsidxlength + 1;
        }
      }
      // alert(this.shopGoodsRegisterData.checkedGoodsidxlength);

      if (
        this.shopGoodsRegisterData.brandgoodslist.length ==
        this.shopGoodsRegisterData.checkedGoodsidxlength
      ) {
        this.shopGoodsRegisterData.allGoodsSelected = true;
      } else {
        this.shopGoodsRegisterData.allGoodsSelected = false;
      }
    },
    BrandShopModalOpen() {
      this.$refs["modal-register-shop"].show();
      this.shopRegisterData.brandgoodslist = [];
      this.shopRegisterData.brandgoodslist_ = [];
      this.shopRegisterData.searchshoplist = [];
      this.shopRegisterData.searchshopbrandno = 0;
      this.shopRegisterData.searchshopname = 0;
      this.shopRegisterData.searchshopname = "";
      this.shopRegisterData.searchshopno = 0;
    },
    BrandGoodsModalOpen(shopno, shopname) {
      this.$refs["modal-register-club"].show();
      this.shopGoodsRegisterData.goodsshopname = shopname;
      this.getRoomBrandlist(shopno);
      this.getShopRoomlist(shopno);
      this.shopGoodsRegisterData.brandgoodslist = [];
      this.shopGoodsRegisterData.brandgoodslist_ = [];
      this.shopGoodsRegisterData.form.checkedGoodsidx = [];
      this.shopGoodsRegisterData.form.checkedgoods = [];
      this.shopGoodsRegisterData.form.goodsbrandno = 0;
      this.shopGoodsRegisterData.form.goodsshopno = shopno;
      this.shopGoodsRegisterData.form.goodsroomno = 0;
      this.shopGoodsRegisterData.form.brandgoodsno = [];

      ax.get_shopinfo(shopno, 0, (flag, data) => {
        if (flag) {
          this.roomgoodslist = data.roomgoodslist;

          if (data.roomgoodslist) {
            for (let i = 0; i < data.roomgoodslist.length; i++) {
              for (
                let j = 0;
                j < data.roomgoodslist[i].roomgoodsinfodetail.length;
                j++
              ) {
                this.roomgoodsinfodetail[j] =
                  data.roomgoodslist[i].roomgoodsinfodetail[j];
                // console.log(j, this.roomgoodsinfodetail[j]);
              }
            }
          }

          // this.getShopRoomlist(shopno);
        } else {
          alert(data);
        }
      });
    },
    getBrandGoodsInfoSearch() {
      // if (!this.shopGoodsRegisterData.form.goodsbrandno) {
      //   alert(this.$i18n.t("alert.common.select_brand1"));
      //   return false;
      // }
      ax.get_brandgoodsinfo(
        this.shopGoodsRegisterData.form.goodsbrandno,
        (flag, data) => {
          // console.log(flag, data);
          if (flag) {
            this.shopGoodsRegisterData.allGoodsSelected = false;
            this.shopGoodsRegisterData.form.checkedGoodsidx = [];
            this.shopGoodsRegisterData.form.checkedgoods = [];
            this.shopGoodsRegisterData.form.brandshaftmaterialcode = {};
            // this.playerno = playerno;
            this.$refs["modal-register-club"].modalOuterStyle.zIndex = 9999;
            this.$refs["modal-register-club"].show();
            // this.shopGoodsRegisterData.goodsshopname = shopname;

            this.shopGoodsRegisterData.brandgoodslist = data.brandgoodslist;

            this.shopGoodsRegisterData.brandgoodslist_ =
              data.brandgoodslist.filter((v, idx) => {
                return (
                  data.brandgoodslist.findIndex((v1) => {
                    return v.brandgoodstype === v1.brandgoodstype;
                  }) === idx
                );
              });

            this.shopGoodsRegisterData.goods_total_cnt =
              data.brandgoodslist.length;
            if (data.brandgoodslist) {
              // for (let i = 0; i < data.brandgoodslist.length; i++) {
              //   this.shopGoodsRegisterData.form.brandshaftmaterialcode[i] = "";
              //   this.shopGoodsRegisterData.form.brandgoodsno[i] =
              //     data.brandgoodslist[i].brandgoodsno;
              //   this.shopGoodsRegisterData.form.checkedGoodsidx.push(false);
              // }
            }
            console.log(
              "this.shopGoodsRegisterData.brandgoodslist",
              this.shopGoodsRegisterData.brandgoodslist
            );
            // this.getShopRoomlist(shopno);
          } else {
            this.shopGoodsRegisterData.brandgoodslist = [];
            // alert(data);
          }
        }
      );
      // this.getRoomBrandlist(shopno);
      // this.getShopRoomlist(shopno);
    },
    setShopRoomRegister() {
      console.log(
        "this.shopGoodsRegisterData.form.brandshaftmaterialcode",
        this.shopGoodsRegisterData.form.brandshaftmaterialcode
      );
      // alert(this.form.checklist.length);
      this.shopGoodsRegisterData.form.brandgoodscnt =
        this.shopGoodsRegisterData.form.checkedgoods.length;

      if (!this.shopGoodsRegisterData.form.goodsbrandno) {
        alert(this.$i18n.t("alert.common.select_brand2"));
        return false;
      }

      if (!this.shopGoodsRegisterData.form.goodsroomno) {
        alert(this.$i18n.t("alert.common.select_room_number"));
        return false;
      }

      // alert(this.shopGoodsRegisterData.form.checkedGoodsidx.length);
      if (this.shopGoodsRegisterData.form.checkedgoods.length > 0) {
        if (
          !this.shopGoodsRegisterData.form.checkedgoods.every((v) => {
            console.log(
              "v",
              this.shopGoodsRegisterData.form.brandshaftmaterialcode[v]
            );

            if (!this.shopGoodsRegisterData.form.brandshaftmaterialcode[v])
              return false;
            return true;
          })
        ) {
          alert(this.$i18n.t("alert.common.enter_strength"));
          return false;
        }
      } else {
        alert(this.$i18n.t("alert.common.select_goods"));
        return false;
      }
      ax.set_shoproomregister(
        this.shopGoodsRegisterData.form,
        (flag, response) => {
          // console.log(flag, response);
          if (flag) {
            alert(response.message);
            this.getShoplist();
            // this.sendData.shopmemo = "";
            this.$bvModal.hide("modal-register-club");
          } else {
            /*this.$notify({
              group: 'alert',
              type: 'info',
              title: '정보',
              text: '등록에 실패하였습니다.'
            })*/
            alert(response.message);
          }
        }
      );
    },
    shopDel() {
      if (this.checkedShopidx.length < 1) {
        alert(this.$i18n.t("alert.common.select_item"));
      } else {
        if (
          confirm(
            this.$i18n.t("alert.common.checked_be_deleted") +
              "\n" +
              this.$i18n.t("alert.common.want_to_proceed")
          )
        ) {
          this.sendData.fit_shop_pk = [];
          for (let i = 0; i < this.checkedShopidx.length; i++) {
            this.sendData.fit_shop_pk.push(
              this.shoplist[this.checkedShopidx[i]].fit_shop_pk
            );
          }

          ax.post_shopdel(this.sendData, (flag, response) => {
            alert(response.message);
            if (flag) {
              this.getShoplist();
              this.checkedShopidx = [];
            }
          });
        }
      }
    },
    isChecked(val) {
      return this.shopGoodsRegisterData.form.checkedgoods.includes(val);
    },
    checkName(e) {
      let arr = [...this.shopGoodsRegisterData.form.checkedgoods];
      // 자식 컴포넌트에서 데이터를 함부로 직접 바꾸면 안되기 때문에 전개연산자로
      // 얕은 복사를 한 뒤 변수에 담고 해당 데이터를 가공한 뒤 다시 $emit 으로 전달
      if (arr.includes(e.target.value)) {
        arr = arr.filter((name) => name !== e.target.value);
      } else {
        arr.push(e.target.value);
      }
      this.shopGoodsRegisterData.form.checkedgoods = arr;
    },
    toggle_shop_tr(index) {
      if (this.view_hide_shop_club === index) this.view_hide_shop_club = null;
      else this.view_hide_shop_club = index;
    },
    // goods_imgview(imagename) {
    //   if (imagename !== null && imagename !== "") {
    //     //   return this.adlist[index].path + "/" + this.adlist[index].adimagepath;
    //     return process.env.VUE_APP_S3_BUCKET_URL + "/" + imagename;
    //   } else {
    //     return "/img/no-img.jpg";
    //   }
    // },
  },
  mixins: [myMixin],
  created() {
    this.getShoplist();
    this.getBrandlist();
    ax.get_brandview((flag, data) => {
      if (flag) {
        this.checkedManagerEmail = data.brandinfo.email;
      } else {
        alert(data);
      }
    });
  },
  watch: {
    "searchData.searchtype": function () {
      this.searchData.searchname = "";
    },

    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.getShoplist();
    },

    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.getShoplist();
    },
    "searchData.findex": function () {
      this.searchData.pageno = 1;
      this.getShoplist();
    },
    "searchData.pageno": function () {
      this.getShoplist();
    },
    "shopGoodsRegisterData.form.goodsbrandno": function (newVal, oldVal) {
      this.getBrandGoodsInfoSearch();
    },
    "shopGoodsRegisterData.form.goodsroomno": function (newVal, oldVal) {
      this.roomgoodslist_ = this.roomgoodslist.filter((v, idx) => {
        return (
          this.roomgoodslist.findIndex((v1) => {
            return newVal === v1.roomno && v.roomgoodstype === v1.roomgoodstype;
          }) === idx
        );
      });
    },
  },
};
</script>
