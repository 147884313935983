<template>
  <div class="container-fluid">
    <locations />
    <div class="page">
      <b-form @submit="onSubmit">
        <div class="row g-0 table-type header-col">
          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.id") }}</span>
          </div>
          <div class="col-sm-8">
            <b-form-input
              type="text"
              :placeholder="$t('text.common.check_letter_3_20')"
              name="brandid"
              id="brandid"
              v-model="form.brandid"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.new_password") }}</span>
          </div>
          <div class="col-sm-8">
            <b-form-input
              type="password"
              :placeholder="$t('title.common.new_password')"
              name="newpassword"
              v-model="form.newpassword"
            ></b-form-input>
            <p class="mt-2 text-subscript">{{ formValidation.password }}</p>
          </div>

          <div class="col-sm-4">
            <span class="required">{{
              $t("title.common.confirm_new_password")
            }}</span>
          </div>
          <div class="col-sm-8">
            <b-form-input
              type="password"
              :placeholder="$t('title.common.confirm_new_password')"
              name="newpassword_re"
              v-model="form.newpassword_re"
            ></b-form-input>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.brand_company") }}</span>
          </div>
          <div class="col-sm-8">
            <b-form-input
              type="text"
              :placeholder="$t('text.common.check_letter_2_20')"
              name="brandname"
              v-model="form.brandname"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-4">
            <span class="required">{{
              $t("title.common.company_reg_no")
            }}</span>
          </div>
          <div class="col-sm-8">
            <b-form-input
              type="text"
              :placeholder="$t('title.common.company_reg_no')"
              name="brandcomno"
              v-model="form.brandcomno"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.phone") }}</span>
          </div>
          <div class="col-sm-8">
            <b-form-input
              type="text"
              :placeholder="$t('title.common.phone')"
              name="brandphone"
              v-model="form.brandphone"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-4">
            <span>{{ $t("title.location.fax") }}</span>
          </div>
          <div class="col-sm-8">
            <b-form-input
              type="text"
              :placeholder="$t('title.location.fax')"
              name="brandfax"
              v-model="form.brandfax"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-4">
            <span class="required">{{
              $t("title.location.address_location")
            }}</span>
          </div>
          <div class="col-sm-8">
            <b-row class="g-2">
              <b-col sm="12" class="input-group flex-nowrap">
                <b-form-input
                  type="text"
                  :placeholder="$t('title.common.post_code')"
                  name="zipnum"
                  v-model="form.zipnum"
                  readonly
                ></b-form-input>
                <b-button
                  size="md"
                  variant="primary"
                  :placeholder="$t('title.common.search_post_code')"
                  @click="search()"
                  >{{ $t("title.common.post_code") }}</b-button
                >
              </b-col>
              <b-col sm="12"
                ><b-form-input
                  type="text"
                  :placeholder="$t('title.common.street_name_address')"
                  id="roadAddress"
                  name="address1"
                  v-model="form.address1"
                  readonly
                ></b-form-input
              ></b-col>
              <b-col sm="12"
                ><b-form-input
                  type="text"
                  :placeholder="$t('title.common.street_no_address')"
                  id="jibunAddress"
                  name="address2"
                  v-model="form.address2"
                  readonly
                ></b-form-input
              ></b-col>
              <b-col sm="12"
                ><b-form-input
                  type="text"
                  :placeholder="$t('title.common.detailed_address')"
                  id="detailAddress"
                  name="address3"
                  v-model="form.address3"
                  required
                ></b-form-input
              ></b-col>
              <b-col sm="12"
                ><b-form-input
                  type="text"
                  :placeholder="$t('title.common.referenced_item')"
                  id="extraAddress"
                  name="address4"
                  v-model="form.address4"
                  readonly
                ></b-form-input
              ></b-col>
            </b-row>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.department") }}</span>
          </div>
          <div class="col-sm-8">
            <b-form-input
              type="text"
              :placeholder="$t('text.common.check_letter_30')"
              name="managerpart"
              v-model="form.managerpart"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.manager") }}</span>
          </div>
          <div class="col-sm-8">
            <b-row class="g-2">
              <b-col sm="9">
                <b-form-input
                  type="text"
                  :placeholder="$t('text.common.check_letter_30')"
                  name="managername"
                  v-model="form.managername"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="3">
                <select
                  :placeholder="$t('title.common.position')"
                  name="managergrade"
                  v-model="form.managergrade"
                  required
                >
                  <option value="1" :selected="form.managergrade === 1">
                    {{ $t("text.common.general_manager") }}
                  </option>
                  <option value="2" :selected="form.managergrade === 2">
                    {{ $t("text.common.deputy_manager") }}
                  </option>
                  <option value="3" :selected="form.managergrade === 3">
                    {{ $t("text.common.manager") }}
                  </option>
                  <option value="4" :selected="form.managergrade === 4">
                    {{ $t("text.common.associate_manager") }}
                  </option>
                  <option value="5" :selected="form.managergrade === 5">
                    {{ $t("text.common.staff") }}
                  </option>
                </select>
              </b-col>
            </b-row>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.direct_line") }}</span>
          </div>
          <div class="col-sm-8">
            <b-form-input
              type="text"
              :placeholder="$t('title.common.direct_line')"
              name="managerphone"
              v-model="form.managerphone"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.mobile_phone") }}</span>
          </div>
          <div class="col-sm-8">
            <b-row class="g-2">
              <b-col sm="9">
                <b-form-input
                  type="text"
                  :placeholder="$t('title.common.mobile_phone')"
                  name="managermobile"
                  v-model="form.managermobile"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="3" class="d-flex align-items-center" v-if="brandno">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="managermobilechk"
                  id="flexCheck1"
                  v-model="managermobilechk"
                />
                <label for="flexCheck1">{{ $t("title.common.change") }}</label>
              </b-col>
            </b-row>
          </div>

          <div class="col-sm-4" v-if="managermobilechk === true">
            <span>{{ $t("title.common.new_mobile_phone") }}</span>
          </div>
          <div class="col-sm-8" v-if="managermobilechk === true">
            <b-form-input
              type="text"
              :placeholder="$t('title.common.new_mobile_phone')"
              name="newmanagermobile"
              v-model="form.newmanagermobile"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.location.email") }}</span>
          </div>
          <div class="col-sm-8">
            <b-form-input
              type="text"
              :placeholder="$t('text.common.check_letter_100')"
              name="manageremail"
              v-model="form.manageremail"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-4">
            <span class="required">{{
              $t("title.common.account_number")
            }}</span>
          </div>
          <div class="col-sm-8">
            <b-row class="g-2">
              <b-col sm="4">
                <b-form-input
                  type="text"
                  :placeholder="$t('text.common.check_letter_30')"
                  name="depositbank"
                  v-model="form.depositbank"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  :placeholder="$t('title.common.account_number')"
                  name="depositno"
                  v-model="form.depositno"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  :placeholder="$t('title.common.account_owner')"
                  name="depositname"
                  v-model="form.depositname"
                  required
                ></b-form-input>
              </b-col>
            </b-row>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.logo_write") }}</span>
          </div>
          <div class="col-sm-8 d-flex flex-column">
            <b-form-file
              v-model="file1"
              ref="file1"
              :state="Boolean(file1)"
              :placeholder="$t('text.common.file_select')"
              :drop-placeholder="$t('text.common.file_select')"
              @change="fileSelect"
            >
            </b-form-file>
            <div class="col-4 mt-2" v-if="form.brandimagetxt">
              <img :src="form.brandimagetxt | get_img" class="img-fluid">
            </div>
          </div>
        </div>

        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="outline-primary"
            :aria-label="$t('button.common.cancel')"
            @click="$router.go(-1)"
            >{{ $t("button.common.cancel") }}</b-button
          >
          <b-button
            type="submit"
            size="xl"
            variant="primary"
            :aria-label="$t('button.common.write')"
          >
            {{
              brandno ? $t("button.common.edit") : $t("button.common.write")
            }}</b-button
          >
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import locations from "@/components/Location";
import ax from "@/api/brand";
import "@/filter/common";

export default {
  name: "BrandWrite",
  data() {
    return {
      profile: [],
      form: {
        brandid: "",
        newpassword: "",
        newpassword_re: "",
        brandname: "",
        brandcomno: "",
        brandphone: "",
        brandfax: "",
        zipnum: "",
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        managerpart: "",
        managername: "",
        managergrade: 1,
        managerphone: "",
        managermobile: "",
        newmanagermobile: "",
        manageremail: "",
        depositbank: "",
        depositno: "",
        depositname: "",
        brandimage: "",
        brandimagetxt: "",
      },
      file1: null,
      brandno: this.$route.params.brandno || 0,
      formValidation: {
        password: this.$i18n.t("text.common.check_letter_8_30"),
      },
      managermobilechk: false,
      view_hide: null,
      VUE_APP_S3_BUCKET_URL: process.env.VUE_APP_S3_BUCKET_URL,
      targetFileName: null,
    };
  },
  props: ["password_yn"],
  components: {
    locations,
  },
  methods: {
    getAdminbrandview() {
      if (this.brandno) {
        ax.get_adminbrandview(this.brandno, (flag, data) => {
          console.log(flag, data);
          if (flag) {
            this.form = data.brandinfo;
            this.form.brandimagetxt = data.brandinfo.brandimage;
          } else {
            alert(data.message);
          }
        });
      }
    },
    search() {
      //@click을 사용할 때 함수는 이렇게 작성해야 한다.
      let postcode = null;
      if (postcode === null) {
        postcode = new window.daum.Postcode({
          oncomplete: (data) => {
            //function이 아니라 => 로 바꿔야한다.
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

            // 도로명 주소의 노출 규칙에 따라 주소를 표시한다.
            // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
            var roadAddr = data.roadAddress; // 도로명 주소 변수
            var extraRoadAddr = ""; // 참고 항목 변수

            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraRoadAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraRoadAddr +=
                extraRoadAddr !== ""
                  ? ", " + data.buildingName
                  : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraRoadAddr !== "") {
              extraRoadAddr = " (" + extraRoadAddr + ")";
            }

            // 우편번호와 주소 정보를 해당 필드에 넣는다.
            this.form.zipnum = data.zonecode;
            this.form.address1 = roadAddr;
            this.form.address2 = data.jibunAddress;

            // 참고항목 문자열이 있을 경우 해당 필드에 넣는다.
            if (roadAddr !== "") {
              this.form.address4 = extraRoadAddr;
            } else {
              this.form.address4 = "";
            }

            // var guideTextBox = document.getElementById("guide");
            // // 사용자가 '선택 안함'을 클릭한 경우, 예상 주소라는 표시를 해준다.
            // if (data.autoRoadAddress) {
            //   var expRoadAddr = data.autoRoadAddress + extraRoadAddr;
            //   guideTextBox.innerHTML =
            //     "(예상 도로명 주소 : " + expRoadAddr + ")";
            //   guideTextBox.style.display = "block";
            // } else if (data.autoJibunAddress) {
            //   var expJibunAddr = data.autoJibunAddress;
            //   guideTextBox.innerHTML =
            //     "(예상 지번 주소 : " + expJibunAddr + ")";
            //   guideTextBox.style.display = "block";
            // } else {
            //   guideTextBox.innerHTML = "";
            //   guideTextBox.style.display = "none";
            // }
          },
        }).open();
      }
    },
    onSubmit(event) {
      event.preventDefault();

      if (this.form.newpassword) {
        if (this.form.newpassword_re === "") {
          alert(this.$i18n.t("alert.common.enter_new_password"));
          return false;
        }

        if (this.form.newpassword !== this.form.newpassword_re) {
          alert(this.$i18n.t("alert.common.match_password"));
          return false;
        }
      }

      if (this.form.brandname === "") {
        alert(this.$i18n.t("alert.common.enter_brand"));
        return false;
      }

      if (this.form.brandcomno === "") {
        alert(this.$i18n.t("alert.common.enter_company_no"));
        return false;
      }

      if (this.form.brandphone === "") {
        alert(this.$i18n.t("alert.common.enter_phone"));
        return false;
      }

      if (this.form.brandfax === "") {
        alert(this.$i18n.t("alert.common.enter_fax"));
        return false;
      }

      // if (this.form.brandphone === "") {
      //   alert("대표번호를 입력하세요.");
      //   return false;
      // }
      if (this.form.zipnum === "") {
        alert(this.$i18n.t("alert.common.enter_address"));
        return false;
      }

      if (this.form.address3 === "") {
        alert(this.$i18n.t("alert.common.enter_detailed_address"));
        return false;
      }

      if (this.form.managerpart === "") {
        alert(this.$i18n.t("alert.common.enter_department"));
        return false;
      }

      if (this.form.managername === "") {
        alert(this.$i18n.t("alert.common.enter_manager"));
        return false;
      }

      if (this.form.managerphone === "") {
        alert(this.$i18n.t("alert.common.enter_directed_line"));
        return false;
      }

      // if (this.form.managermobile === "") {
      //   alert("담당자 휴대번호를 입력하세요.");
      // alert(this.$i18n.t("alert.common.enter_mobile_phone"));
      //   return false;
      // }

      if (this.form.manageremail === "") {
        alert(this.$i18n.t("alert.common.enter_email"));
        return false;
      }

      ax.post_adminbrandwrite(this.form, this.brandno, (flag, data) => {
        alert(data.message);

        if (flag) {
          this.$router.push({
            name: "AdminBrandList",
          });
        } else {
          return false;
        }
      });
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    fileSelect(e) {
      // alert(e.dataTransfer.files[0]);
      if (e.dataTransfer) {
        this.form.brandimage = e.dataTransfer.files[0];
      } else {
        this.form.brandimage = e.target.files[0];
      }
    },

    // fileSelect() {
    //     this.form.brandimage = this.$refs.file.files[0];
    // },
    // handleFileChange(e) {
    //   this.targetFileName = e.target.files[0].name;
    //   this.form.brandimage = this.$refs.file.files[0];
    // },
  },

  created() {
    this.getAdminbrandview();
  },
};
</script>