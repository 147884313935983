var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-main"},[_c('swiper',{staticClass:"swiper mySwiper",attrs:{"options":{
      slidesPerView: 'auto',
      freeMode: true,
      initialSlide: _vm.tab - 1,
    }}},[_c('swiper-slide',{class:{ active: _vm.tab == 0 }},[_c('div',{class:{
          active: _vm.tab == 0,
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'NoticeList',
              query: { category: '0' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("filter.common.total"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 1 }},[_c('div',{class:{
          active: _vm.tab == 1,
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'NoticeList',
              query: { category: '1' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("menu.customer.notice"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 2 }},[_c('div',{class:{
          active: _vm.tab == 2,
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'NoticeList',
              query: { category: '2' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("menu.customer.as"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 3 }},[_c('div',{class:{
          active: _vm.tab == 3,
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'NoticeList',
              query: { category: '3' },
            })
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("menu.customer.proposal"))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }