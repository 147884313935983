<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page">
      <b-form @submit="onSubmit">
        <div class="filter-group row">
          <div class="col-sm-auto mb-2 mb-sm-0 col-sm-6 col-md-5">
            <div class="d-flex">
              <select
                class="w-auto"
                :aria-label="$t('text.common.input_search')"
                name="searchtype"
                v-model="searchData.searchtype"
              >
                <option value="G.fit_goods_nm">
                  {{ $t("filter.common.goods_name") }}
                </option>
                <option value="GS.fit_goods_shaft_nm">
                  {{ $t("filter.common.shaft_name") }}
                </option>
                <!-- <option value="R.state_cd">클럽정보</option> -->
                <option value="P.nickname">
                  {{ $t("filter.common.nickname") }}
                </option>
                <option value="G.fit_goods_nm">
                  {{ $t("filter.common.location") }}
                </option>
              </select>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control text-truncate"
                  :placeholder="$t('text.common.input_search')"
                  :aria-label="$t('text.common.input_search')"
                  aria-describedby="button-addon"
                  name="searchname"
                  v-model="searchData.searchname"
                />
                <button
                  class="btn btn-primary btn-search"
                  type="submit"
                  id="button-addon"
                ></button>
              </div>
            </div>
          </div>

          <div class="col-sm-auto ms-sm-auto col-sm-5 col-md-4">
            <div class="d-flex">
              <select aria-label="" v-model="searchData.findex">
                <option value="R.fit_buy_date" selected>
                  {{ $t("filter.common.sort_by_date") }}
                </option>
                <option value="R.state_cd">
                  {{ $t("filter.common.sort_by_status") }}
                </option>
                <option value="G.fit_goods_nm">
                  {{ $t("filter.common.sort_by_goods_name") }}
                </option>
              </select>
              <select aria-label="" v-model="searchData.pagesize">
                <option value="10">{{ $t("filter.common.sort_by_10") }}</option>
                <option value="20">{{ $t("filter.common.sort_by_20") }}</option>
                <option value="30">{{ $t("filter.common.sort_by_30") }}</option>
              </select>
            </div>
          </div>
        </div>
      </b-form>
      <table class="table board">
        <thead>
          <tr>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.common.no") }}
            </th>
            <th class="col-auto d-lg-table-cell">
              {{ $t("title.common.goods_name") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.common.club_information") }}
            </th>
            <th class="col-auto d-lg-table-cell w-1 text-center">
              {{ $t("title.common.status") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.common.nickname") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.common.location") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.common.date") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="total_cnt">
          <template v-for="(item, index) in salelist">
            <tr :key="index">
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.saleinfo.no }}
              </td>
              <td class="col-auto d-lg-table-cell text-start">
                <router-link
                  :to="{
                    name: 'FittingSaleView',
                    params: {
                      fittinganswerno: item.saleinfo.fittinganswerno,
                    },
                    query: { goodstype: searchData.goodstype },
                  }"
                >
                  <p class="goods-kind">
                    {{ item.saleinfo.goodstype | get_club_type }}({{
                      item.saleinfo.clubtype_code_name
                    }})
                  </p>
                  <p class="goods-name">{{ item.saleinfo.goodsname }}</p>
                </router-link>
              </td>
              <td class="col-auto d-none d-lg-table-cell text-start">
                <router-link
                  :to="{
                    name: 'FittingSaleView',
                    params: {
                      fittinganswerno: item.saleinfo.fittinganswerno,
                    },
                    query: { goodstype: searchData.goodstype },
                  }"
                >
                  {{ $t("page.goods.shaft") }}({{ item.saleinfo.shaftname }} /
                  {{ item.saleinfo.shaftmaterial | get_shaft_material }}
                  /
                  {{ item.saleinfo.shaftflextype | get_balance }})<br />
                  {{ $t("title.common.loft") }} ({{
                    item.saleinfo.goodsloft || "-"
                  }}) {{ $t("title.common.lie") }} ({{
                    item.saleinfo.goodslie || "-"
                  }}) {{ $t("title.common.sole") }} ({{
                    item.saleinfo.goodssole || "-"
                  }}) {{ $t("title.common.fp") }} ({{
                    item.saleinfo.goodsfp || "-"
                  }}) {{ $t("title.common.length") }} ({{
                    item.saleinfo.goodslength || "-"
                  }}) {{ $t("title.common.weight") }} ({{
                    item.saleinfo.goodsweight || "-"
                  }})
                </router-link>
              </td>
              <td class="col-auto d-lg-table-cell">
                <button
                  class="btn btn-xs w-100 text-center"
                  :class="[
                    {
                      'bg-orange text-white':
                        item.saleinfo.fittingstatus == '1',
                    },
                    {
                      'bg-green text-white': item.saleinfo.fittingstatus == '2',
                    },
                    {
                      'bg-secondary bg-opacity-50 text-white':
                        item.saleinfo.fittingstatus == '3',
                    },
                  ]"
                  type="button"
                  @click="pop_delivery(item.saleinfo.fitreqno)"
                >
                  {{ item.saleinfo.fittingstatus | get_state_cd }}
                </button>
              </td>
              <td class="col-auto d-none d-lg-table-cell date">
                <MemberInfo
                  :nickname="item.saleinfo.nickname"
                  :memberno="item.saleinfo.memberno"
                />
              </td>
              <td class="col-auto d-none d-lg-table-cell shop-name">
                {{ item.saleinfo.shopname }}
              </td>
              <td class="col-auto d-none d-lg-table-cell text-date">
                {{ item.saleinfo.timezone_orderdate | dateformat }}
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="col-auto d-lg-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="7">
                <div class="toggle-content">
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.no") }}</div>
                    <div class="col-8">
                      {{ item.saleinfo.no }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.common.club_information") }}
                    </div>
                    <div class="col-8">
                      <router-link
                        :to="{
                          name: 'FittingSaleView',
                          params: {
                            fittinganswerno: item.saleinfo.fittinganswerno,
                          },
                          query: { goodstype: searchData.goodstype },
                        }"
                      >
                        {{ $t("page.goods.shaft") }}({{
                          item.saleinfo.shaftname
                        }}
                        /
                        {{ item.saleinfo.shaftmaterial | get_shaft_material }}
                        /
                        {{ item.saleinfo.shaftflextype | get_balance }})<br />
                        {{ $t("title.common.loft") }} ({{
                          item.saleinfo.goodsloft || "-"
                        }}) {{ $t("title.common.lie") }} ({{
                          item.saleinfo.goodslie || "-"
                        }}) {{ $t("title.common.sole") }} ({{
                          item.saleinfo.goodssole || "-"
                        }}) {{ $t("title.common.fp") }} ({{
                          item.saleinfo.goodsfp || "-"
                        }}) {{ $t("title.common.length") }} ({{
                          item.saleinfo.goodslength || "-"
                        }}) {{ $t("title.common.weight") }} ({{
                          item.saleinfo.goodsweight || "-"
                        }})
                      </router-link>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.nickname") }}</div>
                    <div class="col-8">
                      <MemberInfo
                        :nickname="item.saleinfo.nickname"
                        :memberno="item.saleinfo.memberno"
                      />
                      <!-- {{ item.saleinfo.nickname }} -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.location") }}</div>
                    <div class="col-8 shop-name">
                      {{ item.saleinfo.shopname }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.date") }}</div>
                    <div class="col-8 text-date">
                      {{ item.saleinfo.timezone_orderdate | dateformat }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="text-center">
              {{ $t("text.common.no_data") }}
            </td>
          </tr>
        </tbody>
      </table>
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Pagination"
        class="mt-3"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
    <b-modal
      id="modal-shipping-process"
      ref="modal-shipping-process"
      size="lg"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title">{{ $t("title.common.shipping_process") }}</h5>
        <b-button variant="close" @click="close()"></b-button>
      </template>
      <template>
        <div class="row g-0 table-type header-col border-1">
          <div class="col-sm-3">
            {{ $t("title.common.brand_name") }}
          </div>
          <div class="col-sm-9 brand-name">
            {{ deliverySendData.info.brandname }}
          </div>
          <div class="col-sm-3">
            {{ $t("title.common.goods_name") }}
          </div>
          <div class="col-sm-9 goods-name">
            {{ deliverySendData.info.fit_goods_nm }}
          </div>
          <div class="col-sm-3">
            <span>{{ $t("title.common.receiver_name") }}</span>
          </div>
          <div class="col-sm-9 basic-info">
            <span class="shop-name">{{ deliverySendData.info.shopname }}</span
            ><br />
            {{ deliverySendData.info.phone }}
            <span class="material-icons-outlined">phone</span>
          </div>
          <div class="col-sm-3">
            <span>{{ $t("title.common.address_location") }}</span>
          </div>
          <div class="col-sm-9">
            {{ deliverySendData.info.address }}
          </div>
          <div class="col-sm-3">
            <span class="required">{{
              $t("title.common.shipping_company")
            }}</span>
          </div>
          <div class="col-sm-9">
            <select v-model="deliverySendData.info.ship_no">
              <option value="0">{{ $t("text.common.choose") }}</option>
              <option
                v-for="(val, index) in deliverySendData.shiplist"
                :selected="val.ship_pk == deliverySendData.info.ship_no"
                :key="'s' + index"
                :value="val.ship_pk"
              >
                {{ val.ship_nm }}
              </option>
            </select>
          </div>
          <div class="col-sm-3">
            <span class="required">{{ $t("text.common.invoice_number") }}</span>
          </div>
          <div class="col-sm-9">
            <input
              type="text"
              v-model="deliverySendData.info.ship_invoice_no"
            />
          </div>
        </div>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.common.cancel')"
          @click="cancel()"
        >
          {{ $t("button.common.cancel") }}
        </b-button>
        <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.sales.shipping_tracking')"
          @click="onClickRedirect()"
          >{{ $t("button.sales.shipping_tracking") }}</b-button
        >
        <b-button
          size="md"
          variant="secondary"
          :aria-label="$t('button.common.confirm')"
          type="button"
          @click="confirm"
        >
          {{ $t("button.common.confirm") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/sale/Tab";
import ax from "@/api/sale";
import "@/filter/common";

export default {
  name: "FittingSaleList",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "G.fit_goods_nm",
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        goodstype:
          this.$route.query.goodstype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].goodstype) ||
          0,
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
        findex: "R.fit_buy_date",
        pagesize: "10",
      },

      page_total: 1,
      total_cnt: 0,
      rows: 10,
      salelist: [],
      view_hide: null,
      deliverySendData: { info: {}, shiplist: [] },
    };
  },
  methods: {
    onClickRedirect: function () {
      if (
        !this.deliverySendData.info.ship_invoice_no ||
        !this.deliverySendData.info.ship_no
      ) {
        alert(this.$i18n.t("alert.common.enter_shipping_company_number"));
        return false;
      } else {
        window.open(
          this.deliverySendData.shiplist.find(
            (v) => v.ship_pk == this.deliverySendData.info.ship_no
          ).ship_url + this.deliverySendData.info.ship_invoice_no,
          "_blank"
        );
      }
    },
    get_salelist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });

      ax.get_salelist(this.searchData, (flag, data = "") => {
        // console.log(flag, data);
        this.total_cnt = data.pagination.salelistcnt;
        this.page_total = Math.ceil(
          data.pagination.salelistcnt / this.searchData.pagesize
        );
        this.salelist = data.salelist;
        if (!flag) alert(data.message);
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      // this.$store.commit("setSearchData", {
      //   [this.$route.name]: this.searchData,
      // });
      this.get_salelist();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },

    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    pop_delivery(fitreqno) {
      ax.get_delivery(fitreqno, (flag, data) => {
        // console.log(flag, data);
        if (flag) {
          this.deliverySendData = data.delivery;

          this.$refs["modal-shipping-process"].show();
        } else alert(data.message);
      });
    },
    confirm() {
      if (
        !this.deliverySendData.info.ship_no ||
        this.deliverySendData.info.ship_no === "0"
      ) {
        alert(this.$i18n.t("alert.common.enter_shipping_company"));
        return false;
      }

      if (this.deliverySendData.info.ship_invoice_no === "") {
        alert(this.$i18n.t("alert.common.enter_shipping_number"));
        return false;
      }

      ax.post_deliverywrite(this.deliverySendData.info, (flag, data) => {
        alert(data.message);
        if (flag) {
          this.$refs["modal-shipping-process"].hide();
          this.deliverySendData = { info: {}, shiplist: [] };
          this.get_salelist();
        }
      });
    },
  },
  created() {
    this.get_salelist();
  },
  watch: {
    "$route.query.goodstype": function () {
      (this.searchData.goodstype = this.$route.query.goodstype || "0"),
        (this.searchData.pageno = 1);
      this.view_hide = null;
      this.get_salelist();
    },
    "searchData.searchtype": function () {
      this.searchData.searchname = "";
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_salelist();
    },
    "searchData.findex": function () {
      this.searchData.pageno = 1;
      this.get_salelist();
    },
    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.get_salelist();
    },
    "searchData.pageno": function () {
      this.get_salelist();
    },
  },
};
</script>