<template>
  <div class="container-fluid">
    <location />
    <div class="page">
      <article class="article-header row g-0">
        <div class="col-12">
          <h5>{{ noticeinfo.noticetitle }}</h5>
        </div>
        <div class="d-flex flex-column flex-sm-row">
          <div class="d-flex align-items-center">
            <span
              class="icon badge fill-purple"
              :class="noticeinfo.category | get_custormer_category_bg"
            >
              {{ noticeinfo.category | get_custormer_category }}
            </span>
            <span class="shop-name">{{ noticeinfo.writer }}</span>
          </div>
          <div class="ms-sm-auto mt-2 mt-sm-0">
            <span class="me-2 text-date">
              <i class="material-icons">date_range </i>
              {{ noticeinfo.timezone_regdate | dateformat }}
            </span>
            <span>
              <i class="material-icons">visibility</i>
              {{ noticeinfo.viewcnt | comma }}
            </span>
          </div>
        </div>
      </article>

      <article class="article-attach" v-if="noticeinfo.as_filename">
        <a :href="noticeinfo.as_download_link" class="text-truncate">
          <i class="material-icons-outlined">file_download</i>
          <span>{{ noticeinfo.as_filename }}</span>
        </a>
      </article>

      <article class="article-body">
        <span v-html="noticeinfo.content"></span>
      </article>

      <!-- 하단답변부분 -->
      <b-form v-if="noticeinfo.status === $t('button.common.done')">
        <div class="row g-0 table-type header-col border-bottom-0">
          <template v-if="noticeinfo.category == '2'">
            <div class="col-sm-3">
              <span class="required">{{
                $t("title.common.estimated_cost")
              }}</span>
            </div>
            <div class="col-sm-9 text-price">
              &#8361; {{ noticeinfo.answerprice | comma }}
            </div>
          </template>
          <div class="col-sm-3">
            <span class="required">{{
              $t("title.common.answer_content")
            }}</span>
          </div>
          <div class="col-sm-9">
            <span v-html="noticeinfo.answercontent"></span>
          </div>

          <div class="col-sm-3">
            {{ $t("title.common.answer_attached_file") }}
          </div>
          <div class="col-sm-9">
            <article
              class="article-attach p-0 border-bottom-0"
              v-if="noticeinfo.as_ans_filename"
            >
              <a :href="noticeinfo.as_ans_download_link" class="text-truncate">
                <i class="material-icons-outlined">file_download</i>
                <span>{{ noticeinfo.as_ans_filename }}</span>
              </a>
            </article>
          </div>
        </div>
      </b-form>

      <article class="article-footer">
        <div class="prev" :class="!next_post.fit_as_pk && 'no-data'">
          <template v-if="next_post.fit_as_pk">
            <router-link
              :to="{
                name: 'NoticeView',
                params: { noticeno: next_post.fit_as_pk },
              }"
            >
              <i class="material-icons-outlined"> expand_less </i>
              <span>{{ next_post.title }}</span>
            </router-link>
          </template>
          <template v-else>
            <a>
              <i class="material-icons-outlined"> expand_less </i>
              <span>{{ $t("text.common.no_prev") }}</span>
            </a>
          </template>
        </div>
        <div class="next" :class="!prev_post.fit_as_pk && 'no-data'">
          <template v-if="prev_post.fit_as_pk">
            <router-link
              :to="{
                name: 'NoticeView',
                params: { noticeno: prev_post.fit_as_pk },
              }"
            >
              <i class="material-icons-outlined"> expand_more </i>
              <span>{{ prev_post.title }}</span>
            </router-link>
          </template>
          <template v-else>
            <a>
              <i class="material-icons-outlined"> expand_more </i>
              <span>{{ $t("text.common.no_next") }}</span>
            </a>
          </template>
        </div>
      </article>

      <div class="btn-bottom-wrapper" v-if="noticeinfo.can_modify">
        <b-button
          size="xl"
          variant="outline-secondary"
          :aria-label="$t('button.common.list')"
          @click="
            $router.push({
              name: 'NoticeList',
            })
          "
          >{{ $t("button.common.list") }}</b-button
        >
        <b-button
          size="xl"
          variant="secondary"
          :aria-label="$t('button.common.delete')"
          @click="noticeDelete"
          >{{ $t("button.common.delete") }}</b-button
        >
        <b-button
          size="xl"
          variant="primary"
          :aria-label="$t('button.common.edit')"
          @click="
            $router.push({
              name: 'NoticeWrite',
              params: { noticeno: $route.params.noticeno },
              query: { category: searchData.category },
            })
          "
        >
          {{ $t("button.common.edit") }}
        </b-button>
      </div>

      <div class="btn-bottom-wrapper" v-if="noticeinfo.can_reply">
        <b-button
          size="xl"
          variant="outline-primary"
          :aria-label="$t('button.common.list')"
          @click="
            $router.push({
              name: 'NoticeList',
            })
          "
          >{{ $t("button.common.list") }}</b-button
        >
        <b-button
          size="xl"
          variant="primary"
          :aria-label="$t('button.common.done')"
          @click="
            $router.push({
              name: 'NoticeReplyWrite',
              params: { noticeno: $route.params.noticeno },
              query: { category: searchData.category },
            })
          "
          >{{
            noticeinfo.status === $t("button.common.done")
              ? $t("button.customer.answer_modify")
              : $t("button.customer.answer_write")
          }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/customer";
import "@/filter/common";

export default {
  name: "NoticeView",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "fit_as_title",
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        category:
          this.$route.query.category ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].category) ||
          "",
      },

      brandno:
        (this.$store.state.admin && this.$store.state.admin.brandno) || "0",
      noticeno: this.$route.params.noticeno,
      noticeinfo: { content: "" },
      next_post: {},
      prev_post: {},

      // mainbrandno: 0,
    };
  },
  methods: {
    get_custormerview() {
      // if (this.mainbrandno) {
      // this.brandno = this.mainbrandno;
      // }
      if (this.noticeno) {
        ax.get_custormerview(
          this.noticeno,
          this.searchData,
          this.brandno,
          (flag, data) => {
            console.log(flag, data);
            if (flag) {
              this.noticeinfo = data.noticeinfo;
              this.next_post = data.next_post;
              this.prev_post = data.prev_post;

              // if (sessionStorage.getItem("isAdmin") == "1") {
              //   if (
              //     data.noticeinfo.brandno &&
              //     this.mainbrandno != data.noticeinfo.brandno
              //   ) {
              //     this.$store.commit("setBrandno", data.noticeinfo.brandno);
              //     // window.location.reload(true);
              //   }
              //   this.brandno = data.noticeinfo.brandno;
              // }
            } else {
              alert(data);
            }
          }
        );
      }
    },
    noticeDelete() {
      ax.delete_custormerdelete(this.noticeno, (flag, data) => {
        if (flag) {
          alert(data.message);
          // this.$notify({
          //   group: "alert",
          //   type: "success",
          //   text: data.message,
          //   speed: 1000,
          // });
          this.$router.push({
            path: "/customer/noticelist/" + this.searchData.category,
          });
        } else {
          alert(data.message);
        }
      });
    },
  },
  created() {
    this.get_custormerview();
  },
  watch: {
    "$route.params": function () {
      this.noticeno = parseInt(this.$route.params.noticeno);
      this.get_custormerview();
    },
  },
};
</script>
