<template>
  <div class="tab-main">
    <swiper
      :options="{
        slidesPerView: 'auto',
        freeMode: true,
        initialSlide: 0,
      }"
      class="swipe"
    >
      <swiper-slide :class="{ active: tab == '0' }">
        <div
          @click="
            $router
              .push({
                name: 'FittingSaleList',
                query: { goodstype: '0' },
              })
              .catch(() => {})
          "
        >
          {{ $t("filter.common.total") }}
        </div>
      </swiper-slide>

      <swiper-slide :class="{ active: tab == 1 }">
        <div
          @click="
            $router
              .push({
                name: 'FittingSaleList',
                query: { goodstype: 1 },
              })
              .catch(() => {})
          "
        >
          {{ $t("filter.common.driver") }}
        </div>
      </swiper-slide>

      <swiper-slide :class="{ active: tab == 2 }">
        <div
          @click="
            $router
              .push({
                name: 'FittingSaleList',
                query: { goodstype: 2 },
              })
              .catch(() => {})
          "
        >
          {{ $t("filter.common.wood") }}
        </div>
      </swiper-slide>

      <swiper-slide :class="{ active: tab == 3 }">
        <div
          @click="
            $router
              .push({
                name: 'FittingSaleList',
                query: { goodstype: 3 },
              })
              .catch(() => {})
          "
        >
          {{ $t("filter.common.utility") }}
        </div>
      </swiper-slide>

      <swiper-slide :class="{ active: tab == 4 }">
        <div
          @click="
            $router
              .push({
                name: 'FittingSaleList',
                query: { goodstype: 4 },
              })
              .catch(() => {})
          "
        >
          {{ $t("filter.common.iron") }}
        </div>
      </swiper-slide>

      <swiper-slide :class="{ active: tab == 5 }">
        <div
          @click="
            $router
              .push({
                name: 'FittingSaleList',
                query: { goodstype: 5 },
              })
              .catch(() => {})
          "
        >
          {{ $t("filter.common.wedge") }}
        </div>
      </swiper-slide>

      <swiper-slide :class="{ active: tab == 6 }">
        <div
          @click="
            $router
              .push({
                name: 'FittingSaleList',
                query: { goodstype: 6 },
              })
              .catch(() => {})
          "
        >
          {{ $t("filter.common.putter") }}
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "Tab",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /*swiperOptions: {
        slidesPerView: 'auto',
        freeMode: true
      }*/
    };
  },
  computed: {
    tab: function () {
      if (this.$route.query.goodstype) {
        return parseInt(this.$route.query.goodstype);
      } else {
        return 0;
      }
    },
  },

  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>
