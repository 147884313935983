<template>
  <div class="container-fluid">
    <location />
    <div class="page">
      <div
        class="d-flex justify-content-end align-items-center mt-lg-1 mb-2 mb-lg-0"
      >
        <!-- <button @click="get_ansresultlist" class="btn btn-xs ps-0">
          <span class="material-icons-outlined">outbox</span>
          <span class="d-inline">&nbsp;불러오기</span>
        </button> -->
        <div class="btn-group">
          <b-button
            @click="tabIndex--"
            class="btn-xs border-0 bg-transparent text-black"
          >
            <span class="material-icons-outlined fs-6">arrow_back_ios</span>
            <span class="d-inline">&nbsp;{{ $t("button.common.prev") }}</span>
          </b-button>
          <b-button
            @click="tabIndex++"
            class="btn-xs pe-0 border-0 bg-transparent text-black"
          >
            <span class="d-inline">{{ $t("button.common.next") }}&nbsp;</span>
            <span class="material-icons-outlined fs-6">arrow_forward_ios</span>
          </b-button>
        </div>
      </div>
      <div class="progress-step-arrow mt-lg-2">
        <b-card no-body>
          <b-tabs v-model="tabIndex" card justified>
            <!-- TabIndex : 0, 피팅리포트 -->
            <b-tab :title="$t('page.fitting.fitting_report')">
              <section>
                <h6 class="sub-title">
                  {{ $t("title.common.fitting_total_analysis") }}
                </h6>
                <div class="row g-0 table-type header-col">
                  <div class="col-sm-5 col-md-4">
                    {{ $t("title.common.fitting_analysis") }}
                  </div>
                  <div class="col-sm-7 col-md-8">
                    <textarea
                      class="form-control"
                      v-model="form.resulttxt"
                      readonly
                    ></textarea>
                  </div>
                  <div class="col-sm-5 col-md-4 border-sm-0">
                    {{ $t("title.common.attachment") }}
                  </div>
                  <div class="col-sm-7 col-md-8">
                    <p
                      v-if="
                        fittingreqinfo.fit_ans_file &&
                        fittingreqinfo.fit_ans_file_is_image == '1'
                      "
                      class="text-subscript text-truncate"
                    >
                      <a
                        @click="$bvModal.show('modal-img-thumbnail')"
                        class="more"
                      >
                        {{ fittingreqinfo.fit_ans_file_originname }}
                      </a>
                    </p>

                    <p
                      v-if="
                        fittingreqinfo.fit_ans_file &&
                        fittingreqinfo.fit_ans_file_is_image == '0'
                      "
                      class="text-subscript text-truncate"
                    >
                      <a :href="fittingreqinfo.download_link" class="more">
                        <i class="material-icons-outlined align-middle fs-5"
                          >file_download</i
                        >
                        <span>{{
                          fittingreqinfo.fit_ans_file_originname
                        }}</span>
                      </a>
                    </p>
                  </div>
                </div>
              </section>
              <!-- //피팅 종합 분석 -->

              <section class="fitting-anls">
                <h6 class="sub-title">
                  {{ $t("title.common.pitch_club_analysis") }}
                  <mark class="font-monospace text-orange"
                    >({{ fittingreqinfo.fit_club_type | get_club_type }}
                    {{ $t("text.common.standard") }})</mark
                  >
                </h6>
                <div class="card-group">
                  <div class="row g-3">
                    <!-- 공통 : 구질분석 -->
                    <div class="col-md-4">
                      <div class="card h-100">
                        <div class="card-body">
                          <Radar
                            class="pitch-anls"
                            :chart-data="radardata"
                            :chart-options="radaroptions"
                          />
                        </div>
                        <!-- //card-body -->
                        <div
                          class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                        >
                          <div class="row gx-2 gy-2 text-nowrap fs-14">
                            <div class="col">
                              <div class="d-flex flex-column">
                                <p class="mb-2">
                                  <small class="rounded-circle bg-green"
                                    >1</small
                                  >{{ $t("title.common.distance") }}
                                </p>
                                <select
                                  disabled="true"
                                  class="w-auto"
                                  :aria-label="$t('title.common.distance')"
                                  v-model="form.distancegrade"
                                >
                                  <option
                                    v-for="val in 5"
                                    :key="'distancegrade' + val"
                                    :value="val"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col">
                              <div class="d-flex flex-column">
                                <p class="mb-2">
                                  <small class="rounded-circle bg-orange"
                                    >2</small
                                  >{{ $t("title.common.directionality") }}
                                </p>
                                <select
                                  disabled="true"
                                  class="w-auto"
                                  :aria-label="
                                    $t('title.common.directionality')
                                  "
                                  v-model="form.directiongrade"
                                >
                                  <option
                                    v-for="val in 5"
                                    :key="'directiongrade' + val"
                                    :value="val"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col">
                              <div class="d-flex flex-column">
                                <p class="mb-2">
                                  <small class="rounded-circle bg-pink">3</small
                                  >{{ $t("title.common.accuracy") }}
                                </p>
                                <select
                                  disabled="true"
                                  class="w-auto"
                                  :aria-label="$t('title.common.accuracy')"
                                  v-model="form.accuracygrade"
                                >
                                  <option
                                    v-for="val in 5"
                                    :key="'accuracygrade' + val"
                                    :value="val"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col">
                              <div class="d-flex flex-column">
                                <p class="mb-2">
                                  <small class="rounded-circle bg-purple"
                                    >4</small
                                  >{{ $t("title.common.posture") }}
                                </p>
                                <select
                                  disabled="true"
                                  class="w-auto"
                                  :aria-label="$t('title.common.posture')"
                                  v-model="form.positiongrade"
                                >
                                  <option
                                    v-for="val in 5"
                                    :key="'positiongrade' + val"
                                    :value="val"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col">
                              <div class="d-flex flex-column">
                                <p class="mb-2">
                                  <small class="rounded-circle bg-blue">5</small
                                  >{{ $t("title.common.impact") }}
                                </p>
                                <select
                                  disabled="true"
                                  class="w-auto"
                                  :aria-label="$t('title.common.impact')"
                                  v-model="form.impactgrade"
                                >
                                  <option
                                    v-for="val in 5"
                                    :key="'impactgrade' + val"
                                    :value="val"
                                  >
                                    {{ val }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- //card-footer -->
                      </div>
                    </div>
                    <!-- //공통 : 구질분석 -->
                    <!-- 클럽분석 : 드라이버, 우드, 유틸 -->
                    <div
                      class="col-md-4 border-end-md border-start-md"
                      v-if="fittingreqinfo.fit_club_type < 4"
                    >
                      <div class="card h-100">
                        <div
                          class="card-body d-flex justify-content-center p-0"
                        >
                          <img
                            src="/img/fit_anls_dvr_tv.png"
                            class="align-self-center img-fluid"
                            :alt="$t('title.common.club_analysis')"
                          />
                        </div>
                        <!-- //card-body -->
                        <div
                          class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                        >
                          <div class="row gx-2 gy-2 text-nowrap fs-14">
                            <div class="col">
                              <div class="d-flex flex-column">
                                <p class="mb-2">
                                  <small class="rounded-circle bg-green"
                                    >1</small
                                  >{{ $t("title.common.head_volume") }}
                                </p>
                                <p class="d-flex align-items-center">
                                  <input
                                    type="text"
                                    class="text-truncate"
                                    id="head-volume"
                                    :placeholder="
                                      $t('title.common.head_volume')
                                    "
                                    :aria-label="$t('title.common.head_volume')"
                                    v-model="form.headvolume"
                                    required
                                    readonly
                                  />
                                  <label for="head-volume" class="ms-1"
                                    >㎤</label
                                  >
                                </p>
                              </div>
                            </div>
                            <div class="col">
                              <div class="d-flex flex-column">
                                <p class="mb-2">
                                  <small class="rounded-circle bg-orange"
                                    >2</small
                                  >{{ $t("title.common.loft") }}
                                </p>
                                <p class="d-flex align-items-center">
                                  <input
                                    type="text"
                                    class="text-truncate"
                                    id="loft"
                                    :placeholder="$t('title.common.loft')"
                                    :aria-label="$t('title.common.loft')"
                                    v-model="form.loft"
                                    required
                                    readonly
                                  />
                                  <label for="loft" class="ms-1">&deg;</label>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- //card-footer -->
                      </div>
                    </div>

                    <div
                      class="col-md-4"
                      v-if="fittingreqinfo.fit_club_type < 4"
                    >
                      <div class="card h-100">
                        <div
                          class="card-body d-flex justify-content-center p-0"
                        >
                          <img
                            src="/img/fit_anls_dvr_fv.png"
                            class="align-self-center img-fluid"
                            :alt="$t('title.common.club_analysis')"
                          />
                        </div>
                        <!-- //card-body -->
                        <div
                          class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top"
                        >
                          <div class="row gx-2 gy-2 text-nowrap fs-14">
                            <div class="col">
                              <div class="d-flex flex-column">
                                <p class="mb-2">
                                  <small class="rounded-circle bg-green"
                                    >1</small
                                  >{{ $t("title.common.lie") }}
                                </p>
                                <p class="d-flex align-items-center">
                                  <input
                                    readonly
                                    type="text"
                                    class="text-truncate"
                                    id="lie"
                                    :placeholder="$t('title.common.lie')"
                                    :aria-label="$t('title.common.lie')"
                                    v-model="form.lie"
                                  />
                                  <label for="lie" class="ms-1">&deg;</label>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- //card-footer -->
                      </div>
                    </div>
                    <!-- //클럽분석 : 드라이버, 우드, 유틸 -->

                    <!-- 클럽분석 : 아이언, 웨지 -->
                    <div
                      class="col-md-4 border-end-md border-start-md"
                      v-if="fittingreqinfo.fit_club_type > 3"
                    >
                      <div class="card h-100">
                        <div
                          class="card-body d-flex justify-content-center p-0"
                        >
                          <img
                            src="/img/fit_anls_iron_tv.png"
                            class="align-self-center img-fluid"
                            :alt="$t('title.common.club_analysis')"
                          />
                        </div>
                        <!-- //card-body -->
                        <div
                          class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                        >
                          <div class="row gx-2 gy-2 text-nowrap fs-14">
                            <div class="col">
                              <div class="d-flex flex-column">
                                <p class="mb-2">
                                  <small class="rounded-circle bg-green"
                                    >1</small
                                  >{{ $t("title.common.bounce") }}
                                </p>
                                <p class="d-flex align-items-center">
                                  <input
                                    readonly
                                    type="text"
                                    class="text-truncate"
                                    id="bounce"
                                    :placeholder="$t('title.common.bounce')"
                                    :aria-label="$t('title.common.bounce')"
                                    v-model="form.bounce"
                                    required
                                  />
                                  <label for="bounce" class="ms-1">&deg;</label>
                                </p>
                              </div>
                            </div>
                            <div class="col">
                              <div class="d-flex flex-column">
                                <p class="mb-2">
                                  <small class="rounded-circle bg-orange"
                                    >2</small
                                  >{{ $t("title.common.fp") }}
                                </p>
                                <p class="d-flex align-items-center">
                                  <input
                                    readonly
                                    type="text"
                                    class="text-truncate"
                                    id="FP"
                                    :placeholder="$t('title.common.fp')"
                                    :aria-label="$t('title.common.fp')"
                                    v-model="form.fp"
                                    required
                                  />
                                  <label for="FP" class="ms-1">㎜</label>
                                </p>
                              </div>
                            </div>
                            <div class="col">
                              <div class="d-flex flex-column">
                                <p class="mb-2">
                                  <small class="rounded-circle bg-pink">3</small
                                  >{{ $t("title.common.sole") }}
                                </p>
                                <p class="d-flex align-items-center">
                                  <input
                                    readonly
                                    type="text"
                                    class="text-truncate"
                                    id="sole"
                                    :placeholder="$t('title.common.sole')"
                                    :aria-label="$t('title.common.sole')"
                                    v-model="form.sole"
                                    required
                                  />
                                  <label for="sole" class="ms-1">&deg;</label>
                                </p>
                              </div>
                            </div>
                            <div class="col">
                              <div class="d-flex flex-column">
                                <p class="mb-2">
                                  <small class="rounded-circle bg-purple"
                                    >4</small
                                  >{{ $t("title.common.loft") }}
                                </p>
                                <p class="d-flex align-items-center">
                                  <input
                                    readonly
                                    type="text"
                                    class="text-truncate"
                                    id="loft"
                                    :placeholder="$t('title.common.loft')"
                                    :aria-label="$t('title.common.loft')"
                                    v-model="form.loft"
                                    required
                                  />
                                  <label for="loft" class="ms-1">&deg;</label>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- //card-footer -->
                      </div>
                    </div>

                    <div
                      class="col-md-4"
                      v-if="fittingreqinfo.fit_club_type > 3"
                    >
                      <div class="card h-100">
                        <div
                          class="card-body d-flex justify-content-center p-0"
                        >
                          <img
                            src="/img/fit_anls_iron_fv.png"
                            class="align-self-center img-fluid"
                            :alt="$t('title.common.club_analysis')"
                          />
                        </div>
                        <!-- //card-body -->
                        <div
                          class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top"
                        >
                          <div class="row gx-2 gy-2 text-nowrap fs-14">
                            <div class="col">
                              <div class="d-flex flex-column">
                                <p class="mb-2">
                                  <small class="rounded-circle bg-green"
                                    >1</small
                                  >{{ $t("title.common.lie") }}
                                </p>
                                <p class="d-flex align-items-center">
                                  <input
                                    readonly
                                    type="text"
                                    class="text-truncate"
                                    id="lie"
                                    :placeholder="$t('title.common.lie')"
                                    :aria-label="$t('title.common.lie')"
                                    v-model="form.lie"
                                  />
                                  <label for="lie" class="ms-1">&deg;</label>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- //card-footer -->
                      </div>
                    </div>
                    <!-- //클럽분석 : 아이언, 웨지 -->
                  </div>
                </div>
              </section>
              <!-- //구질 및 클럽 분석  -->

              <div class="btn-bottom-wrapper">
                <b-button-group>
                  <b-button
                    @click="tabIndex--"
                    size="xl"
                    variant="outline-secondary btn-prev"
                    disabled
                    :aria-label="$t('button.common.prev')"
                  >
                    <span class="material-icons-outlined">arrow_back_ios</span>
                  </b-button>
                  <b-button
                    @click="tabIndex++"
                    size="xl"
                    variant="outline-secondary btn-next"
                    :aria-label="$t('button.common.next')"
                  >
                    <span class="material-icons-outlined"
                      >arrow_forward_ios</span
                    >
                  </b-button>
                </b-button-group>
              </div>
            </b-tab>

            <!-- TabIndex : 1, 원포인트레슨 -->
            <b-tab :title="$t('page.fitting.one_point_lesson')">
              <section class="fitting-lesson">
                <h6 class="sub-title">
                  {{ $t("title.fitting.one_point_lesson") }}
                </h6>
                <div class="row g-0 table-type header-col">
                  <div class="col-sm-5 col-md-4">
                    {{ "lesson1" | get_lesson_title }}
                  </div>
                  <div class="col-sm-7 col-md-8">
                    <b-form-textarea
                      :placeholder="$t('text.common.lessons_for_each_item')"
                      readonly
                      class="mb-2"
                      v-model="form.analysisresulttxt['lesson1']"
                    ></b-form-textarea>
                    <div class="rating-wrapper d-sm-inline-flex">
                      <label for="rating-address">{{
                        $t("title.common.grade_point_by_action")
                      }}</label>
                      <b-form-rating
                        id="rating-address"
                        v-model="form.analysisresultgrade['lessonlevel1']"
                        no-border
                        show-value
                        show-value-max
                        class="py-1"
                        readonly
                      ></b-form-rating>
                    </div>
                  </div>

                  <div class="col-sm-5 col-md-4">
                    {{ "lesson2" | get_lesson_title }}
                  </div>
                  <div class="col-sm-7 col-md-8">
                    <b-form-textarea
                      :placeholder="$t('text.common.lessons_for_each_item')"
                      readonly
                      class="mb-2"
                      v-model="form.analysisresulttxt['lesson2']"
                    ></b-form-textarea>
                    <div class="rating-wrapper d-sm-inline-flex">
                      <label for="rating-address">{{
                        $t("title.common.grade_point_by_action")
                      }}</label>
                      <b-form-rating
                        id="rating-address"
                        v-model="form.analysisresultgrade['lessonlevel2']"
                        no-border
                        readonly
                        show-value
                        show-value-max
                        class="py-1"
                      ></b-form-rating>
                    </div>
                  </div>

                  <div class="col-sm-5 col-md-4">
                    {{ "lesson3" | get_lesson_title }}
                  </div>
                  <div class="col-sm-7 col-md-8">
                    <b-form-textarea
                      :placeholder="$t('text.common.lessons_for_each_item')"
                      readonly
                      class="mb-2"
                      v-model="form.analysisresulttxt['lesson3']"
                    ></b-form-textarea>
                    <div class="rating-wrapper d-sm-inline-flex">
                      <label for="rating-address">{{
                        $t("title.common.grade_point_by_action")
                      }}</label>
                      <b-form-rating
                        id="rating-address"
                        v-model="form.analysisresultgrade['lessonlevel3']"
                        no-border
                        readonly
                        show-value
                        show-value-max
                        class="py-1"
                      ></b-form-rating>
                    </div>
                  </div>

                  <div class="col-sm-5 col-md-4">
                    {{ "lesson4" | get_lesson_title }}
                  </div>
                  <div class="col-sm-7 col-md-8">
                    <b-form-textarea
                      :placeholder="$t('text.common.lessons_for_each_item')"
                      readonly
                      class="mb-2"
                      v-model="form.analysisresulttxt['lesson4']"
                    ></b-form-textarea>
                    <div class="rating-wrapper d-sm-inline-flex">
                      <label for="rating-address">{{
                        $t("title.common.grade_point_by_action")
                      }}</label>
                      <b-form-rating
                        id="rating-address"
                        v-model="form.analysisresultgrade['lessonlevel4']"
                        no-border
                        readonly
                        show-value
                        show-value-max
                        class="py-1"
                      ></b-form-rating>
                    </div>
                  </div>

                  <div class="col-sm-5 col-md-4">
                    {{ "lesson5" | get_lesson_title }}
                  </div>
                  <div class="col-sm-7 col-md-8">
                    <b-form-textarea
                      :placeholder="$t('text.common.lessons_for_each_item')"
                      readonly
                      class="mb-2"
                      v-model="form.analysisresulttxt['lesson5']"
                    ></b-form-textarea>
                    <div class="rating-wrapper d-sm-inline-flex">
                      <label for="rating-address">{{
                        $t("title.common.grade_point_by_action")
                      }}</label>
                      <b-form-rating
                        id="rating-address"
                        v-model="form.analysisresultgrade['lessonlevel5']"
                        no-border
                        readonly
                        show-value
                        show-value-max
                        class="py-1"
                      ></b-form-rating>
                    </div>
                  </div>

                  <div class="col-sm-5 col-md-4">
                    {{ "lesson6" | get_lesson_title }}
                  </div>
                  <div class="col-sm-7 col-md-8">
                    <b-form-textarea
                      :placeholder="$t('text.common.lessons_for_each_item')"
                      readonly
                      class="mb-2"
                      v-model="form.analysisresulttxt['lesson6']"
                    ></b-form-textarea>
                    <div class="rating-wrapper d-sm-inline-flex">
                      <label for="rating-address">{{
                        $t("title.common.grade_point_by_action")
                      }}</label>
                      <b-form-rating
                        id="rating-address"
                        v-model="form.analysisresultgrade['lessonlevel6']"
                        no-border
                        readonly
                        show-value
                        show-value-max
                        class="py-1"
                      ></b-form-rating>
                    </div>
                  </div>

                  <div class="col-sm-5 col-md-4">
                    {{ "lesson7" | get_lesson_title }}
                  </div>
                  <div class="col-sm-7 col-md-8">
                    <b-form-textarea
                      :placeholder="$t('text.common.lessons_for_each_item')"
                      readonly
                      class="mb-2"
                      v-model="form.analysisresulttxt['lesson7']"
                    ></b-form-textarea>
                    <div class="rating-wrapper d-sm-inline-flex">
                      <label for="rating-address">{{
                        $t("title.common.grade_point_by_action")
                      }}</label>
                      <b-form-rating
                        id="rating-address"
                        v-model="form.analysisresultgrade['lessonlevel7']"
                        no-border
                        readonly
                        show-value
                        show-value-max
                        class="py-1"
                      ></b-form-rating>
                    </div>
                  </div>

                  <div class="col-sm-5 col-md-4 border-sm-0">
                    <span>{{ "lessontotal" | get_lesson_title }}</span>
                  </div>
                  <div class="col-sm-7 col-md-8">
                    <b-form-textarea
                      :placeholder="$t('text.common.overall_review')"
                      readonly
                      v-model="form.analysisresulttxt['lessontotal']"
                    ></b-form-textarea>
                  </div>
                </div>
              </section>

              <div class="btn-bottom-wrapper">
                <b-button-group>
                  <b-button
                    @click="tabIndex--"
                    size="xl"
                    variant="outline-secondary btn-prev"
                    :aria-label="$t('button.common.prev')"
                  >
                    <span class="material-icons-outlined">arrow_back_ios</span>
                  </b-button>
                  <b-button
                    @click="tabIndex++"
                    size="xl"
                    variant="outline-secondary btn-next"
                    :aria-label="$t('button.common.next')"
                  >
                    <span class="material-icons-outlined"
                      >arrow_forward_ios</span
                    >
                  </b-button>
                </b-button-group>
              </div>
            </b-tab>

            <!-- TabIndex : 2, 고급형 -->
            <b-tab :title="$t('page.fitting.premium_goods')">
              <section>
                <h6 class="sub-title">
                  <span class="brand-name fw-bold">{{
                    form.p_info.fit_brand_nm
                  }}</span
                  >{{ $t("title.fitting.recommended_by") }}
                  <span class="text-green">{{
                    $t("title.fitting.premium_goods")
                  }}</span>
                </h6>
                <div class="card">
                  <div class="row g-0 table-type header-col">
                    <div class="col-sm-3">
                      <div
                        class="d-flex flex-fill justify-content-center h-100 bg-white"
                      >
                        <img
                          :src="
                            form.p_info.goodsinfo.fit_goods_image1 | get_img
                          "
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <div
                      class="col-sm-9 d-flex flex-column align-items-stretch"
                    >
                      <div class="card-body px-0 px-sm-3">
                        <h4 class="card-title">
                          {{ form.p_info.goodsinfo.fit_goods_nm }}
                        </h4>
                        <div class="row g-0 row-cols-1 row-cols-sm-2">
                          <div
                            class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 fw-bold text-black"
                          >
                            {{ $t("title.common.retail_price") }}
                          </div>
                          <div class="col-sm-8 py-0 py-sm-2">
                            <h5 class="text-price">
                              &#8361; {{ form.p_info.fit_sale_price | comma }}
                            </h5>
                          </div>

                          <div
                            class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                          >
                            {{ $t("title.common.location_price") }}
                          </div>
                          <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                            <h5 class="text-price">
                              &#8361; {{ form.p_info.fit_shop_price | comma }}
                            </h5>
                          </div>

                          <div
                            class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                          >
                            {{ $t("title.common.xgolf_price") }}
                          </div>
                          <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                            <h5 class="text-price">
                              &#8361; {{ form.p_info.fit_supply_price | comma }}
                            </h5>
                          </div>

                          <div
                            class="col-sm-4 mt-3 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                          >
                            {{ $t("title.common.benefit") }}
                          </div>
                          <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                            {{ form.p_info.goodsinfo.fit_goods_info }}
                            <!-- 미등록 -->
                          </div>
                        </div>
                      </div>
                      <!-- //card-body -->
                      <!-- <div
                        class="card-footer px-0 px-sm-3 py-0 bg-transparent border-0 rounded-0"
                      >
                        <div
                          class="btn-bottom-wrapper mt-2 mb-4 mt-sm-0 mb-sm-3"
                        >
                          <b-button
                            size="md"
                            variant="primary"
                            aria-label="제품구매"
                          >
                            제품구매
                          </b-button>
                        </div>
                      </div> -->
                      <!-- //card-footer -->
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <h6 class="sub-title">
                  {{ $t("title.common.basic_information") }}
                </h6>
                <div class="row g-0 table-type header-col">
                  <div class="col-sm-3">{{ $t("title.common.club_type") }}</div>
                  <div class="col-sm-9">
                    <p class="goods-kind">
                      [{{
                        form.p_info.goodsinfo.fit_club_type | get_club_type
                      }}]
                    </p>
                    {{ form.p_info.goodsinfo.fit_goods_nm }}
                  </div>

                  <div class="col-sm-3">{{ $t("page.goods.shaft") }}</div>
                  <div class="col-sm-9">
                    <b-row class="g-2">
                      <b-col sm="auto">
                        {{ form.p_info.shaftinfo.fit_goods_shaft_nm }}
                      </b-col>
                      <b-col>
                        {{
                          form.p_info.shaftinfo.fit_shaft_material
                            | get_shaft_material
                        }}
                      </b-col>
                    </b-row>
                  </div>

                  <div class="col-sm-3">{{ $t("page.goods.grip") }}</div>
                  <div class="col-sm-9">
                    {{ form.p_info.gripinfo.fit_goods_grip_nm }}
                  </div>
                </div>
              </section>

              <section>
                <h6 class="sub-title">{{ $t("page.goods.club_detail") }}</h6>
                <div class="row g-0 table-type header-col">
                  <img
                    v-if="form.p_info.goodsinfo.fit_goods_image2"
                    :src="form.p_info.goodsinfo.fit_goods_image2 | get_img"
                    class="img-fluid"
                  />
                  <img
                    v-if="form.p_info.goodsinfo.fit_goods_image3"
                    :src="form.p_info.goodsinfo.fit_goods_image3 | get_img"
                    class="img-fluid"
                  />
                </div>
              </section>
              <!-- //기본 정보 등록 -->

              <section class="reg-spec">
                <h6 class="sub-title">{{ $t("page.goods.spec") }}</h6>
                <b-table-simple responsive table-class="border-0">
                  <b-thead>
                    <b-tr>
                      <b-th sticky-column>{{
                        $t("title.common.club_type")
                      }}</b-th>
                      <b-th
                        v-for="(val, idx) in form.p_info.ansclublist"
                        :key="'p_clubnotype' + idx"
                      >
                        {{
                          fittingreqinfo.clubtypelist.find(
                            (v) => v.clubtype_code == val.fit_club_nm
                          ).clubtype_code_name
                        }}
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-th sticky-column
                        >{{ $t("title.common.loft") }}(&deg;)</b-th
                      >
                      <b-td
                        v-for="(val, idx) in form.p_info.ansclublist"
                        :key="'p_clubloft' + idx"
                      >
                        {{ val.fit_club_loft }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th sticky-column
                        >{{ $t("title.common.lie") }}(&deg;)</b-th
                      >
                      <b-td
                        v-for="(val, idx) in form.p_info.ansclublist"
                        :key="'p_clublie' + idx"
                      >
                        {{ val.fit_club_lie }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="form.p_info.goodsinfo.fit_club_type < 4">
                      <b-th sticky-column
                        >{{ $t("title.common.head_volume") }}(㎤)</b-th
                      >
                      <b-td
                        v-for="(val, idx) in form.p_info.ansclublist"
                        :key="'p_clubheadvolume' + idx"
                      >
                        {{ val.fit_club_headvolume }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="form.p_info.goodsinfo.fit_club_type > 3">
                      <b-th sticky-column
                        >{{ $t("title.common.sole") }}(&deg;)</b-th
                      >
                      <b-td
                        v-for="(val, idx) in form.p_info.ansclublist"
                        :key="'p_clubsole' + idx"
                      >
                        {{ val.fit_club_sol }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th sticky-column>{{ $t("title.common.fp") }}(mm)</b-th>
                      <b-td
                        v-for="(val, idx) in form.p_info.ansclublist"
                        :key="'p_clubfp' + idx"
                        >{{ val.fit_club_fp }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th sticky-column
                        >{{ $t("title.common.length") }}(inch)</b-th
                      >
                      <b-td
                        v-for="(val, idx) in form.p_info.ansclublist"
                        :key="'p_clublength' + idx"
                      >
                        {{ val.fit_club_length }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th sticky-column rowspan="5">
                        <div class="sticky-column-rowspan">
                          <div>{{ $t("title.common.balance") }}</div>
                          <div class="flex-column">
                            <div
                              v-if="
                                form.p_info.fit_balance1 ==
                                form.p_info.fit_goods_balance
                              "
                            >
                              {{ form.p_info.fit_balance1 | get_balance }}
                            </div>
                            <div
                              v-if="
                                form.p_info.fit_balance2 ==
                                form.p_info.fit_goods_balance
                              "
                            >
                              {{ form.p_info.fit_balance2 | get_balance }}
                            </div>
                            <div
                              v-if="
                                form.p_info.fit_balance3 ==
                                form.p_info.fit_goods_balance
                              "
                            >
                              {{ form.p_info.fit_balance3 | get_balance }}
                            </div>
                            <div
                              v-if="
                                form.p_info.fit_balance4 ==
                                form.p_info.fit_goods_balance
                              "
                            >
                              {{ form.p_info.fit_balance4 | get_balance }}
                            </div>
                            <div
                              class="py-2 pe-2"
                              v-if="
                                form.p_info.fit_balance5 ==
                                form.p_info.fit_goods_balance
                              "
                            >
                              {{ form.p_info.fit_balance5 | get_balance }}
                            </div>
                          </div>
                        </div>
                      </b-th>
                      <template v-for="(val, idx) in form.p_info.ansclublist">
                        <b-td
                          :key="'p_clubflextitle1' + idx"
                          v-if="
                            form.p_info.fit_balance1 ==
                            form.p_info.fit_goods_balance
                          "
                        >
                          {{ val.fit_club_balance1 }}
                        </b-td>
                      </template>
                    </b-tr>
                    <b-tr>
                      <template v-for="(val, idx) in form.p_info.ansclublist">
                        <b-td
                          :key="'p_clubflextitle2' + idx"
                          v-if="
                            form.p_info.fit_balance2 ==
                            form.p_info.fit_goods_balance
                          "
                        >
                          {{ val.fit_club_balance2 }}
                        </b-td>
                      </template>
                    </b-tr>
                    <b-tr>
                      <template v-for="(val, idx) in form.p_info.ansclublist">
                        <b-td
                          :key="'p_clubflextitle3' + idx"
                          v-if="
                            form.p_info.fit_balance3 ==
                            form.p_info.fit_goods_balance
                          "
                        >
                          {{ val.fit_club_balance3 }}
                        </b-td>
                      </template>
                    </b-tr>
                    <b-tr>
                      <template v-for="(val, idx) in form.p_info.ansclublist">
                        <b-td
                          :key="'p_clubflextitle4' + idx"
                          v-if="
                            form.p_info.fit_balance4 ==
                            form.p_info.fit_goods_balance
                          "
                        >
                          {{ val.fit_club_balance4 }}
                        </b-td>
                      </template>
                    </b-tr>
                    <b-tr>
                      <template v-for="(val, idx) in form.p_info.ansclublist">
                        <b-td
                          :key="'p_clubflextitle5' + idx"
                          v-if="
                            form.p_info.fit_balance5 ==
                            form.p_info.fit_goods_balance
                          "
                        >
                          {{ val.fit_club_balance5 }}
                        </b-td>
                      </template>
                    </b-tr>
                    <b-tr>
                      <b-th sticky-column class="border-0"
                        >{{ $t("title.common.gross_weight") }}(g)</b-th
                      >
                      <b-td
                        v-for="(val, idx) in form.p_info.ansclublist"
                        :key="'p_clubweight' + idx"
                      >
                        {{ val.fit_club_weight }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </section>
              <!-- 스펙 등록 -->

              <!-- 샤프트 & 그립 -->
              <section class="reg-etc">
                <div class="row gx-4 gy-5 border-0">
                  <div class="col-lg-6 d-flex flex-column">
                    <h6 class="sub-title">{{ $t("page.goods.shaft") }}</h6>
                    <div
                      class="card h-100"
                      v-if="form.p_info.shaftinfo.fit_goods_shaft_pk"
                    >
                      <div class="card-body d-flex flex-column p-0">
                        <p class="card-title">
                          {{ form.p_info.shaftinfo.fit_goods_shaft_nm }}
                          /
                          {{
                            form.p_info.shaftinfo.fit_shaft_material
                              | get_shaft_material
                          }}
                        </p>
                        <div
                          class="d-flex justify-content-center align-items-center h-100 py-3"
                        >
                          <img
                            :src="
                              form.p_info.shaftinfo.fit_goods_shaft_image1
                                | get_img
                            "
                            class="img-fluid"
                            :alt="$t('page.goods.shaft')"
                          />
                        </div>
                      </div>
                      <div class="card-footer">
                        <table class="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">
                                {{ $t("title.common.flex") }}
                              </th>
                              <th scope="col">
                                {{ $t("title.common.weight") }}(g)
                              </th>
                              <th scope="col">
                                {{ $t("title.common.torque") }}(˚)
                              </th>
                              <th scope="col">
                                {{ $t("title.common.kick_point") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {{
                                  form.p_info.shaftinfo.fit_goods_shaft_balance
                                    | get_balance
                                }}
                              </td>
                              <td>
                                {{
                                  form.p_info.shaftinfo.fit_goods_shaft_weight
                                }}
                              </td>
                              <td>
                                {{
                                  form.p_info.shaftinfo.fit_goods_shaft_torque
                                }}
                              </td>
                              <td>
                                {{
                                  form.p_info.shaftinfo
                                    .fit_goods_shaft_kickpoint
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="text-center" v-else>
                      {{ $t("text.common.no_data") }}
                    </div>
                  </div>
                  <div class="col-lg-6 d-flex flex-column">
                    <h6 class="sub-title">{{ $t("page.goods.grip") }}</h6>
                    <div class="card h-100" v-if="form.p_info.gripinfo">
                      <div class="card-body d-flex flex-column p-0">
                        <p class="card-title">
                          {{ form.p_info.gripinfo.fit_goods_grip_nm }}
                        </p>
                        <div
                          class="d-flex justify-content-center align-items-center h-100 py-3"
                        >
                          <img
                            :src="
                              form.p_info.gripinfo.fit_goods_grip_image1
                                | get_img
                            "
                            class="img-fluid"
                            :alt="$t('page.goods.grip')"
                          />
                        </div>
                      </div>
                      <div class="card-footer">
                        <table class="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">
                                {{ $t("title.common.standard") }}
                              </th>
                              <th scope="col">
                                {{ $t("title.common.weight") }}(g)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {{
                                  form.p_info.gripinfo.fit_goods_grip_standard
                                }}
                              </td>
                              <td>
                                {{ form.p_info.gripinfo.fit_goods_grip_weight }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="text-center" v-else>
                      {{ $t("text.common.no_data") }}
                    </div>
                  </div>
                </div>
              </section>
              <!-- 샤프트 & 그립 등록 -->

              <div class="btn-bottom-wrapper">
                <b-button-group>
                  <b-button
                    @click="tabIndex--"
                    size="xl"
                    variant="outline-secondary btn-prev"
                    :aria-label="$t('button.common.prev')"
                  >
                    <span class="material-icons-outlined">arrow_back_ios</span>
                  </b-button>
                  <b-button
                    @click="tabIndex++"
                    size="xl"
                    variant="outline-secondary btn-next"
                    :aria-label="$t('button.common.next')"
                  >
                    <span class="material-icons-outlined"
                      >arrow_forward_ios</span
                    >
                  </b-button>
                </b-button-group>
              </div>
            </b-tab>
            <!-- //tabIndex: 2, 고급형 -->

            <!-- TabIndex : 3, 보급형 -->
            <b-tab :title="$t('page.fitting.entry_goods')">
              <section>
                <h6 class="sub-title">
                  <span class="brand-name fw-bold">{{
                    form.c_info.fit_brand_nm
                  }}</span
                  >{{ $t("title.fitting.recommended_by") }}
                  <span class="text-green">{{
                    $t("title.fitting.entry_goods")
                  }}</span>
                </h6>
                <div class="card">
                  <div class="row g-0 table-type header-col">
                    <div class="col-sm-3">
                      <div
                        class="d-flex flex-fill justify-content-center h-100 bg-white"
                      >
                        <img
                          :src="
                            form.c_info.goodsinfo.fit_goods_image1 | get_img
                          "
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <div
                      class="col-sm-9 d-flex flex-column align-items-stretch"
                    >
                      <div class="card-body px-0 px-sm-3">
                        <h4 class="card-title">
                          {{ form.c_info.goodsinfo.fit_goods_nm }}
                        </h4>
                        <div class="row g-0 row-cols-1 row-cols-sm-2">
                          <div
                            class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 fw-bold text-black"
                          >
                            {{ $t("title.common.retail_price") }}
                          </div>
                          <div class="col-sm-8 py-0 py-sm-2">
                            <h5 class="text-price">
                              &#8361; {{ form.c_info.fit_sale_price | comma }}
                            </h5>
                          </div>

                          <div
                            class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                          >
                            {{ $t("title.common.location_price") }}
                          </div>
                          <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                            <h5 class="text-price">
                              &#8361; {{ form.c_info.fit_shop_price | comma }}
                            </h5>
                          </div>

                          <div
                            class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                          >
                            {{ $t("title.common.xgolf_price") }}
                          </div>
                          <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                            <h5 class="text-price">
                              &#8361; {{ form.c_info.fit_supply_price | comma }}
                            </h5>
                          </div>

                          <div
                            class="col-sm-4 mt-3 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                          >
                            {{ $t("title.common.benefit") }}
                          </div>
                          <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                            {{ form.c_info.goodsinfo.fit_goods_info }}
                            <!-- 미등록 -->
                          </div>
                        </div>
                      </div>
                      <!-- //card-body -->
                      <!-- <div
                        class="card-footer px-0 px-sm-3 py-0 bg-transparent border-0 rounded-0"
                      >
                        <div
                          class="btn-bottom-wrapper mt-2 mb-4 mt-sm-0 mb-sm-3"
                        >
                          <b-button
                            size="md"
                            variant="primary"
                            aria-label="제품구매"
                          >
                            제품구매
                          </b-button>
                        </div>
                      </div> -->
                      <!-- //card-footer -->
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <h6 class="sub-title">
                  {{ $t("title.common.basic_information") }}
                </h6>
                <div class="row g-0 table-type header-col">
                  <div class="col-sm-3">{{ $t("title.common.club_type") }}</div>
                  <div class="col-sm-9">
                    <p class="goods-kind">
                      [{{
                        form.c_info.goodsinfo.fit_club_type | get_club_type
                      }}]
                    </p>
                    {{ form.c_info.goodsinfo.fit_goods_nm }}
                    <!-- <span class="d-inline-flex text-price">&#8361; {{ form.c_info.goodsinfo.fit_sale_price | comma }}</span> -->
                  </div>

                  <div class="col-sm-3">{{ $t("page.goods.shaft") }}</div>
                  <div class="col-sm-9">
                    <b-row class="g-2">
                      <b-col sm="auto">
                        {{ form.c_info.shaftinfo.fit_goods_shaft_nm }}
                      </b-col>
                      <b-col>
                        {{
                          form.c_info.shaftinfo.fit_shaft_material
                            | get_shaft_material
                        }}
                        <!-- <span class="d-inline-flex text-price">&#8361; {{ form.c_info.shaftinfo.fit_shaft_price | comma }}</span> -->
                      </b-col>
                    </b-row>
                  </div>

                  <div class="col-sm-3">{{ $t("page.goods.grip") }}</div>
                  <div class="col-sm-9">
                    {{ form.c_info.gripinfo.fit_goods_grip_nm }}
                    <!-- ({{ form.c_info.gripinfo.fit_grip_price | comma }}) -->
                  </div>
                  <!--
                  <div class="col-sm-3 border-sm-0">
                    <span>가격</span>
                    <mark class="ms-1 fs-12 fw-normal font-monospace text-price"
                      >(단위 : 원)
                    </mark>
                  </div>
                  <div class="col-sm-9">
                    <b-row class="g-2">
                      <b-col sm="4" class="form-floating">
                        소비자가 :
                        {{ form.c_info.fit_sale_price | comma }}
                      </b-col>
                      <b-col sm="4" class="form-floating">
                        매장 공급가 :
                        {{ form.c_info.fit_shop_price | comma }}
                      </b-col>
                      <b-col sm="4" class="form-floating">
                        엑스골프 공급가 :
                        {{ form.c_info.fit_supply_price | comma }}
                      </b-col>
                    </b-row>
                  </div>
                   -->
                </div>
              </section>
              <!-- //기본 정보 등록 -->
              <section>
                <h6 class="sub-title">{{ $t("page.goods.club_detail") }}</h6>
                <div class="row g-0 table-type header-col">
                  <img
                    v-if="form.c_info.goodsinfo.fit_goods_image2"
                    :src="form.c_info.goodsinfo.fit_goods_image2 | get_img"
                    class="img-fluid"
                  />
                  <img
                    v-if="form.c_info.goodsinfo.fit_goods_image3"
                    :src="form.c_info.goodsinfo.fit_goods_image3 | get_img"
                    class="img-fluid"
                  />
                </div>
              </section>

              <section class="reg-spec">
                <h6 class="sub-title">{{ $t("page.goods.spec") }}</h6>
                <b-table-simple responsive table-class="border-0">
                  <b-thead>
                    <b-tr>
                      <b-th sticky-column>{{
                        $t("title.common.club_type")
                      }}</b-th>
                      <b-th
                        v-for="(val, idx) in form.c_info.ansclublist"
                        :key="'p_clubnotype' + idx"
                      >
                        {{
                          fittingreqinfo.clubtypelist.find(
                            (v) => v.clubtype_code == val.fit_club_nm
                          ).clubtype_code_name
                        }}
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-th sticky-column
                        >{{ $t("title.common.loft") }}(&deg;)</b-th
                      >
                      <b-td
                        v-for="(val, idx) in form.c_info.ansclublist"
                        :key="'p_clubloft' + idx"
                      >
                        {{ val.fit_club_loft }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th sticky-column
                        >{{ $t("title.common.lie") }}(&deg;)</b-th
                      >
                      <b-td
                        v-for="(val, idx) in form.c_info.ansclublist"
                        :key="'p_clublie' + idx"
                      >
                        {{ val.fit_club_lie }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="form.c_info.goodsinfo.fit_club_type < 4">
                      <b-th sticky-column
                        >{{ $t("title.common.head_volume") }}(㎤)</b-th
                      >
                      <b-td
                        v-for="(val, idx) in form.c_info.ansclublist"
                        :key="'p_clubheadvolume' + idx"
                      >
                        {{ val.fit_club_headvolume }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="form.c_info.goodsinfo.fit_club_type > 3"
                      >">
                      <b-th sticky-column
                        >{{ $t("title.common.sole") }}(&deg;)</b-th
                      >
                      <b-td
                        v-for="(val, idx) in form.c_info.ansclublist"
                        :key="'c_clubsole' + idx"
                      >
                        {{ val.fit_club_sol }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th sticky-column>{{ $t("title.common.fp") }}(mm)</b-th>
                      <b-td
                        v-for="(val, idx) in form.c_info.ansclublist"
                        :key="'p_clubfp' + idx"
                        >{{ val.fit_club_fp }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th sticky-column
                        >{{ $t("title.common.length") }}(inch)</b-th
                      >
                      <b-td
                        v-for="(val, idx) in form.c_info.ansclublist"
                        :key="'p_clublength' + idx"
                      >
                        {{ val.fit_club_length }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th sticky-column rowspan="5">
                        <div class="sticky-column-rowspan">
                          <div>{{ $t("title.common.balance") }}</div>
                          <div class="flex-column">
                            <div
                              v-if="
                                form.c_info.fit_balance1 ==
                                form.c_info.fit_goods_balance
                              "
                            >
                              {{ form.c_info.fit_balance1 | get_balance }}
                            </div>
                            <div
                              v-if="
                                form.c_info.fit_balance2 ==
                                form.c_info.fit_goods_balance
                              "
                            >
                              {{ form.c_info.fit_balance2 | get_balance }}
                            </div>
                            <div
                              v-if="
                                form.c_info.fit_balance3 ==
                                form.c_info.fit_goods_balance
                              "
                            >
                              {{ form.c_info.fit_balance3 | get_balance }}
                            </div>
                            <div
                              v-if="
                                form.c_info.fit_balance4 ==
                                form.c_info.fit_goods_balance
                              "
                            >
                              {{ form.c_info.fit_balance4 | get_balance }}
                            </div>
                            <div
                              class="py-2 pe-2"
                              v-if="
                                form.c_info.fit_balance5 ==
                                form.c_info.fit_goods_balance
                              "
                            >
                              {{ form.c_info.fit_balance5 | get_balance }}
                            </div>
                          </div>
                        </div>
                      </b-th>
                      <template v-for="(val, idx) in form.c_info.ansclublist">
                        <b-td
                          :key="'c_clubflextitle1' + idx"
                          v-if="
                            form.c_info.fit_balance1 ==
                            form.c_info.fit_goods_balance
                          "
                        >
                          {{ val.fit_club_balance1 }}
                        </b-td>
                      </template>
                    </b-tr>
                    <b-tr>
                      <template v-for="(val, idx) in form.c_info.ansclublist">
                        <b-td
                          :key="'c_clubflextitle2' + idx"
                          v-if="
                            form.c_info.fit_balance2 ==
                            form.c_info.fit_goods_balance
                          "
                        >
                          {{ val.fit_club_balance2 }}
                        </b-td>
                      </template>
                    </b-tr>
                    <b-tr>
                      <template v-for="(val, idx) in form.c_info.ansclublist">
                        <b-td
                          :key="'c_clubflextitle3' + idx"
                          v-if="
                            form.c_info.fit_balance3 ==
                            form.c_info.fit_goods_balance
                          "
                        >
                          {{ val.fit_club_balance3 }}
                        </b-td>
                      </template>
                    </b-tr>
                    <b-tr>
                      <template v-for="(val, idx) in form.c_info.ansclublist">
                        <b-td
                          :key="'c_clubflextitle4' + idx"
                          v-if="
                            form.c_info.fit_balance4 ==
                            form.c_info.fit_goods_balance
                          "
                        >
                          {{ val.fit_club_balance4 }}
                        </b-td>
                      </template>
                    </b-tr>
                    <b-tr>
                      <template v-for="(val, idx) in form.p_info.ansclublist">
                        <b-td
                          :key="'c_clubflextitle5' + idx"
                          v-if="
                            form.c_info.fit_balance5 ==
                            form.c_info.fit_goods_balance
                          "
                        >
                          {{ val.fit_club_balance5 }}
                        </b-td>
                      </template>
                    </b-tr>
                    <b-tr>
                      <b-th sticky-column class="border-0"
                        >{{ $t("title.common.weight") }}(g)</b-th
                      >
                      <b-td
                        v-for="(val, idx) in form.c_info.ansclublist"
                        :key="'p_clubweight' + idx"
                      >
                        {{ val.fit_club_weight }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </section>
              <!-- 스펙 등록 -->

              <!-- 샤프트 & 그립 -->
              <section class="reg-etc">
                <div class="row gx-4 gy-5 border-0">
                  <div class="col-lg-6 d-flex flex-column">
                    <h6 class="sub-title">{{ $t("page.common.shaft") }}</h6>
                    <div
                      class="card h-100"
                      v-if="form.c_info.shaftinfo.fit_goods_shaft_pk"
                    >
                      <div class="card-body d-flex flex-column p-0">
                        <p class="card-title">
                          {{ form.c_info.shaftinfo.fit_goods_shaft_nm }}
                          /
                          {{
                            form.c_info.shaftinfo.fit_shaft_material
                              | get_shaft_material
                          }}
                        </p>
                        <div
                          class="d-flex justify-content-center align-items-center h-100 py-3"
                        >
                          <img
                            :src="
                              form.c_info.shaftinfo.fit_goods_shaft_image1
                                | get_img
                            "
                            class="img-fluid"
                            :alt="$t('page.goods.shaft')"
                          />
                        </div>
                      </div>
                      <div class="card-footer">
                        <table class="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">
                                {{ $t("title.common.flex") }}
                              </th>
                              <th scope="col">
                                {{ $t("title.common.weight") }}(g)
                              </th>
                              <th scope="col">
                                {{ $t("title.common.torque") }}(˚)
                              </th>
                              <th scope="col">
                                {{ $t("title.common.kick_point") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {{
                                  form.c_info.shaftinfo.fit_goods_shaft_balance
                                    | get_balance
                                }}
                              </td>
                              <td>
                                {{
                                  form.c_info.shaftinfo.fit_goods_shaft_weight
                                }}
                              </td>
                              <td>
                                {{
                                  form.c_info.shaftinfo.fit_goods_shaft_torque
                                }}
                              </td>
                              <td>
                                {{
                                  form.c_info.shaftinfo
                                    .fit_goods_shaft_kickpoint
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="text-center" v-else>
                      {{ $t("text.common.no_data") }}
                    </div>
                  </div>
                  <div class="col-lg-6 d-flex flex-column">
                    <h6 class="sub-title">{{ $t("page.goods.grip") }}</h6>
                    <div class="card h-100" v-if="form.c_info.gripinfo">
                      <div class="card-body d-flex flex-column p-0">
                        <p class="card-title">
                          {{ form.c_info.gripinfo.fit_goods_grip_nm }}
                        </p>
                        <div
                          class="d-flex justify-content-center align-items-center h-100 py-3"
                        >
                          <img
                            :src="
                              form.c_info.gripinfo.fit_goods_grip_image1
                                | get_img
                            "
                            class="img-fluid"
                            :alt="$t('page.goods.grip')"
                          />
                        </div>
                      </div>
                      <div class="card-footer">
                        <table class="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">
                                {{ $t("title.common.standard") }}
                              </th>
                              <th scope="col">
                                {{ $t("title.common.weight") }}(g)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {{
                                  form.c_info.gripinfo.fit_goods_grip_standard
                                }}
                              </td>
                              <td>
                                {{ form.c_info.gripinfo.fit_goods_grip_weight }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="text-center" v-else>
                      {{ $t("text.common.no_data") }}
                    </div>
                  </div>
                </div>
              </section>
              <div class="btn-bottom-wrapper">
                <b-button-group>
                  <b-button
                    @click="tabIndex--"
                    size="xl"
                    variant="outline-secondary btn-prev"
                    :aria-label="$t('button.common.prev')"
                  >
                    <span class="material-icons-outlined">arrow_back_ios</span>
                  </b-button>
                  <b-button
                    @click="tabIndex++"
                    size="xl"
                    variant="outline-secondary btn-next"
                    disabled
                    :aria-label="$t('button.common.next')"
                  >
                    <span class="material-icons-outlined"
                      >arrow_forward_ios</span
                    >
                  </b-button>
                </b-button-group>
              </div>
            </b-tab>
            <div class="btn-bottom-wrapper">
              <b-button
                size="xl"
                variant="outline-primary"
                :aria-label="$t('button.common.list')"
                @click="
                  $router.push({
                    name: 'FittingList',
                    query: { goodstype: goodstype },
                  })
                "
                >{{ $t("button.common.list") }}</b-button
              >

              <b-button
                size="xl"
                variant="primary"
                :aria-label="$t('button.common.edit')"
                @click="
                  $router.push({
                    name: 'FittingAnswerWrite',
                    params: {
                      fittingno: fittingreqinfo.fit_fk,
                      fitreqno: fitreqno,
                    },
                    query: { goodstype: goodstype },
                  })
                "
              >
                {{ $t("button.common.edit") }}
              </b-button>
            </div>
            <!-- //tabIndex: 3, 보급형 -->
          </b-tabs>
        </b-card>
      </div>

      <!-- //progress-step-arrow -->
      <b-modal
        id="modal-img-thumbnail"
        size="lg"
        centered
        scrollable
        body-class="d-flex justify-content-center"
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">{{ $t("title.common.attachment") }}</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <img :src="form._resultfilename | get_img" class="mw-100 h-100" />
        </template>

        <template #modal-footer="{ cancel }">
          <b-button
            size="md"
            variant="outline-secondary"
            :aria-label="$t('button.common.close')"
            @click="cancel()"
          >
            {{ $t("button.common.close") }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/fitting";
import "@/filter/common";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import { Radar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");

export default {
  name: "FittingAnswerView",
  components: {
    location,
    Radar,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      form: {
        movieadno: 0,
        titleadno: 0,
        goodsadno1: 0,
        goodsadno2: 0,
        goodsadno3: 0,
        resulttxt: "",
        resultfilename: [],
        impactgrade: "1",
        distancegrade: "1",
        directiongrade: "1",
        accuracygrade: "1",
        positiongrade: "1",
        headvolume: 0,
        sole: 0,
        loft: 0,
        fp: 0,
        bounce: 0,
        lie: 0,
        analysisresulttxt: {
          lesson1: "",
          lesson2: "",
          lesson3: "",
          lesson4: "",
          lesson5: "",
          lesson6: "",
          lesson7: "",
          lessontotal: "",
        },
        analysisresultgrade: {
          lessonlevel1: "",
          lessonlevel2: "",
          lessonlevel3: "",
          lessonlevel4: "",
          lessonlevel5: "",
          lessonlevel6: "",
          lessonlevel7: "",
        },
        p_info: {
          goodsinfo: {},
          memberinfo: {},
          answerinfo: [],
          gripinfo: {},
          shaftinfo: {},
        },
        c_info: {
          goodsinfo: {},
          memberinfo: {},
          answerinfo: [],
          gripinfo: {},
          shaftinfo: {},
        },
      },

      fitreqno: this.$route.params.fitreqno,
      fitresultform: {
        result_pk: 0,
      },
      ansresult: {
        goodstype: "0",
        findex: "",
        fit_req_pk: "",
        pageno: 1,
      },
      analysisresultform: [],
      item: {},
      aditems: [],
      goodsitems: [],
      shaftitems: [],
      gripitems: [],
      clubitems: [],
      clubtypeitems: [],
      ansresultitems: {},
      ansresultitemscnt: 0,
      fitresultitems: [],
      analysisresultitems: [],
      goodstype: this.$route.query.goodstype || 0,
      view_hide: null,
      targetFileName: "",
      tabIndex: 0,
      preViewTabIndex: 0,
      modalTabIndex: 1,
      ansmodalTabIndex: 1,

      analysisresulttitle: "",
      fittingreqinfo: {},
      radardata: {
        labels: [
          this.$i18n.t("title.common.distance"),
          this.$i18n.t("title.common.directionality"),
          this.$i18n.t("title.common.accuracy"),
          this.$i18n.t("title.common.posture"),
          this.$i18n.t("title.common.impact"),
        ],
        datasets: [
          {
            backgroundColor: "rgba(179,181,198,0.2)",
            borderColor: "rgba(179,181,198,1)",
            pointBackgroundColor: "rgba(179,181,198,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(179,181,198,1)",
            data: [],
          },
        ],
      },
      radaroptions: {
        scale: {
          r: {
            min: 0, // MIN
            max: 5, // MAX
            beginAtZero: true,
            angleLines: {
              display: true,
              // color: 'red',
            },
            grid: {
              circular: true,
            },
          },
          ticks: {
            stepSize: 1,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
      },
      resultradardata: {
        labels: [
          this.$i18n.t("title.common.distance"),
          this.$i18n.t("title.common.directionality"),
          this.$i18n.t("title.common.accuracy"),
          this.$i18n.t("title.common.posture"),
          this.$i18n.t("title.common.impact"),
        ],
        datasets: [
          {
            backgroundColor: "rgba(179,181,198,0.2)",
            borderColor: "rgba(179,181,198,1)",
            pointBackgroundColor: "rgba(179,181,198,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(179,181,198,1)",
            data: [],
          },
        ],
      },
      watching: true,
    };
  },

  created() {
    this.get_fittinganswerview().then((res) => {
      this.watching = res;
      this.radardata.datasets[0].data = [
        this.form.distancegrade,
        this.form.directiongrade,
        this.form.accuracygrade,
        this.form.positiongrade,
        this.form.impactgrade,
      ];
    });
  },

  methods: {
    get_fittinganswerview() {
      return new Promise((resolve, reject) => {
        ax.get_fittinganswerview(this.fitreqno, (flag, data) => {
          if (flag) {
            this.fittingreqinfo = data.fittingreqinfo;

            this.form.movieadno = this.fittingreqinfo.fit_ad_no_mov;

            this.form.titleadno = this.fittingreqinfo.fit_ad_no_title;

            this.form.goodsadno1 = this.fittingreqinfo.fit_ad_no_img1;

            this.form.goodsadno2 = this.fittingreqinfo.fit_ad_no_img2;

            this.form.goodsadno3 = this.fittingreqinfo.fit_ad_no_img3;

            this.form.resulttxt = this.fittingreqinfo.fit_ans_txt;
            this.form._resultfilename = this.fittingreqinfo.fit_ans_file;
            this.form.impactgrade = this.fittingreqinfo.grade_impact;

            this.form.distancegrade = this.fittingreqinfo.grade_distance;
            this.form.directiongrade = this.fittingreqinfo.grade_direction;
            this.form.accuracygrade = this.fittingreqinfo.grade_accuracy;
            this.form.positiongrade = this.fittingreqinfo.grade_position;

            this.form.headvolume = this.fittingreqinfo.club_headvolume;
            this.form.sole = this.fittingreqinfo.club_sol;
            this.form.loft = this.fittingreqinfo.club_loft;
            this.form.fp = this.fittingreqinfo.club_fp;
            this.form.bounce = this.fittingreqinfo.club_bounce;
            this.form.lie = this.fittingreqinfo.club_lie;

            this.form.analysisresulttxt.lesson1 = this.fittingreqinfo.lesson1;
            this.form.analysisresulttxt.lesson2 = this.fittingreqinfo.lesson2;
            this.form.analysisresulttxt.lesson3 = this.fittingreqinfo.lesson3;
            this.form.analysisresulttxt.lesson4 = this.fittingreqinfo.lesson4;
            this.form.analysisresulttxt.lesson5 = this.fittingreqinfo.lesson5;
            this.form.analysisresulttxt.lesson6 = this.fittingreqinfo.lesson6;
            this.form.analysisresulttxt.lesson7 = this.fittingreqinfo.lesson7;
            this.form.analysisresulttxt.lessontotal =
              this.fittingreqinfo.lessontotal;

            this.form.analysisresultgrade.lessonlevel1 =
              this.fittingreqinfo.lessonlevel1;
            this.form.analysisresultgrade.lessonlevel2 =
              this.fittingreqinfo.lessonlevel2;
            this.form.analysisresultgrade.lessonlevel3 =
              this.fittingreqinfo.lessonlevel3;
            this.form.analysisresultgrade.lessonlevel4 =
              this.fittingreqinfo.lessonlevel4;
            this.form.analysisresultgrade.lessonlevel5 =
              this.fittingreqinfo.lessonlevel5;
            this.form.analysisresultgrade.lessonlevel6 =
              this.fittingreqinfo.lessonlevel6;
            this.form.analysisresultgrade.lessonlevel7 =
              this.fittingreqinfo.lessonlevel7;

            this.fittingreqinfo.answerinfo.forEach((val) => {
              if (val.fit_goods_grade === "P") {
                this.form.p_info = val;
              }
              if (val.fit_goods_grade === "C") {
                this.form.c_info = val;
              }
            });
            console.log(
              "🚀 ~ file: FittingAnswerView.vue:1630 ~ this.fittingreqinfo.answerinfo.forEach ~ this.form.p_info",
              this.form.p_info
            );
            console.log(
              "🚀 ~ file: FittingAnswerView.vue:1634 ~ this.fittingreqinfo.answerinfo.forEach ~ this.form.c_info",
              this.form.c_info
            );
            resolve(true);
          } else {
            reject(false);
            alert(data.message);
          }
        });
      });
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    print() {
      window.print();
    },
  },
  watch: {},
};
</script>
